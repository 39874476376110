import React from 'react'

const ObjectName = ({ object }) => {
  return (
    <div className="flex">
      <div
        className={`mr-2 rounded bg-gray-200 ${object.isChild ? 'ml-4' : ''}`}
        style={{ minWidth: 26 }}
      >
        <img
          alt={`Object ${object.objectId} icon`}
          className="m-1"
          style={{ width: 18, height: 18 }}
          src={`https://api365.solver.no/api/Resource/Icon/${object.defaultIconId}/Image`}
        />
      </div>
      <div className="my-auto">{object.name}</div>
    </div>
  )
}

export default ObjectName
