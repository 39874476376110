import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, TextInput, Modal, MapPicker } from 'components'

import ApiHelper from 'helpers/ApiHelper'

const initialState = {
  name: '',
  address: '',
  municipality: '',
  latitude: '',
  longitude: '',
}

const CreateVisitObjectModal = ({ visible, ...props }) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const [form, setForm] = useState(initialState)

  const onLocationChange = (location) => {
    setForm({
      ...form,
      address: location.address,
      municipalityStr: `${location.municipality} (${location.code})`,
      municipality: location.code,
      latitude: location.coordinates.lat,
      longitude: location.coordinates.lon,
    })
  }

  //  --- Functions ---
  const onChange = (e) => {
    setForm((f) => ({
      ...f,
      [e.target.name]: e.target.value,
    }))
  }

  const createObject = async () => {
    await ApiHelper.createObject(form)
  }

  //  --- Response ---
  return (
    <Modal visible={visible}>
      <h1 className="text-2xl">{t('createObject:title')}</h1>
      <div className="flex h-full flex-col">
        <div className="my-4 flex flex-row">
          <label className="flex-grow">{t('createObject:deviceName')}</label>
          <TextInput
            name="name"
            value={form.name}
            onChange={onChange}
            className="w-2/3"
          />
        </div>
        <div className="mb-4 flex flex-row">
          <label className="flex-grow">{t('createObject:deviceAddress')}</label>
          <TextInput
            name="address"
            value={form.address}
            onChange={onChange}
            className="w-2/3"
          />
        </div>
        <div className="mb-4 flex flex-row">
          <label className="flex-grow">{t('createObject:municipality')}</label>
          <TextInput
            name="municipality"
            value={form.municipalityStr}
            onChange={onChange}
            className="w-2/3"
          />
        </div>
        <MapPicker onLocationChange={onLocationChange} />
        <div className="mt-5 flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={props.toggle}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={createObject}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateVisitObjectModal
