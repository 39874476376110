import React, { useState } from 'react'
import { Button, FlexibleModal, TextInput } from 'components'

const CommandParamModal = ({ label, setIsVisible, executeCommand }) => {
  //  --- Variables ---
  const [param, setParam] = useState('')

  //  --- Response ---
  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="space-y-3">
        <p>{label ? label : 'No label'}</p>
        <TextInput
          className="w-full"
          placeholder="Param value"
          value={param}
          onChange={(e) => setParam(e.target.value)}
        />
        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={() => executeCommand(param)}
          >
            Submit
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default CommandParamModal
