import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  ShimmerCard,
  Spinner,
  Button,
  MenuButton,
  ReactTable,
  TextInput,
} from '..'
import { filterDataByQuery } from 'helpers/ApplicationHelper'
import useFileDownload from 'helpers/FileDownloadHook'

const FilteredTable = ({
  apiName,
  dataApi,
  dataFormatter = null,
  filterCacheKey,
  filterDebounce,
  initialSort,
  placeholder,
  showRefreshButton,
  showShimmer,
  refreshRef,
  removeFooter,
  rowOnClick,
  expandable = false,
  scrollRestore = false,
  tableHeaders,
  queryProps = {},
  ...props
}) => {
  //  --- Variables ---
  let filterTimeout = useRef()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [filter, setFilterState] = useState('')
  const [inputValue, setInputValue] = useState('')
  const history = useHistory()
  const { scrollOffset } = history.location.state || {}
  const { fetchFile } = useFileDownload()
  const { data, isLoading, fetchStatus, refetch } = useQuery(
    [apiName],
    dataApi,
    queryProps
  )

  //  --- Setup ---
  useEffect(() => {
    if (data == null) {
      setRows([])
    } else {
      if (dataFormatter != null) {
        // setRows(dataFormatter(filterDataByQuery(data, filter)))
        setRows(filterDataByQuery(dataFormatter(data), filter))
      } else {
        setRows(filterDataByQuery(data, filter))
      }
    }
  }, [data, filter, dataFormatter])

  const setFilter = useCallback(
    (value) => {
      setFilterState(value)
      if (filterCacheKey) {
        localStorage.setItem(filterCacheKey, value)
      }
    },
    [setFilterState, filterCacheKey]
  )

  useEffect(() => {
    if (filterCacheKey) {
      let cachedFilter = localStorage.getItem(filterCacheKey) || ''
      setFilter(cachedFilter)
      setInputValue(cachedFilter)
    }
  }, [setFilter, filterCacheKey])

  const onClick = useCallback(
    (row, scroll) => {
      if (scrollRestore) {
        history.replace(`${history.location.pathname}`, {
          scrollOffset: scroll,
        })
      }

      if (rowOnClick) {
        rowOnClick(row)
      }
    },
    [history, scrollRestore, rowOnClick]
  )

  const updateFilter = useCallback(
    (event) => {
      const value = event.target.value
      setInputValue(value)
      if (!filterDebounce) {
        setFilter(value)
      } else {
        clearTimeout(filterTimeout.current)
        filterTimeout.current = setTimeout(() => setFilter(value), 500)
      }
    },
    [filterDebounce, setFilter, setInputValue, filterTimeout]
  )

  //  --- Response ---
  if (showShimmer && isLoading)
    return (
      <ShimmerCard showCircle={false} columnCount={showShimmer.columnCount} />
    )

  if (refreshRef) refreshRef.current = refetch

  const enabledQuery =
    queryProps?.enabled !== null || queryProps?.enabled !== undefined
      ? queryProps.enabled
      : true

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row">
        <TextInput
          value={inputValue}
          className="mr-5 flex-grow md:w-1/2"
          onChange={updateFilter}
          placeholder={placeholder}
        />
        <div className="flex flex-row justify-end space-x-2 md:flex-grow">
          {/* {enabledQuery && (
            <>
              {queryProps?.enabledisLoading || isFetching ? (
                <div className="flex">
                  <Spinner className="my-auto mr-5" />
                </div>
              ) : null}
            </>
          )} */}

          <>
            {fetchStatus === 'fetching' ? (
              <div className="flex">
                <Spinner className="my-auto mr-5" />
              </div>
            ) : null}
          </>

          {props.children}
          {showRefreshButton ? (
            <Button onClick={refetch}> {t('application:refresh')} </Button>
          ) : null}
          {data != null && props.downloadUrls ? (
            <MenuButton
              title={t('application:download')}
              options={[
                {
                  key: 'json',
                  title: `${t('application:download')} JSON`,
                  onClick: () =>
                    fetchFile(props.downloadUrls.json, 'export.json'),
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      <div className="mt-4 h-full flex-grow overflow-auto overflow-y-hidden">
        <ReactTable
          columns={tableHeaders}
          data={rows}
          ref={props.tableRef}
          expandable={expandable}
          initialSort={initialSort}
          removeFooter={removeFooter}
          scrollOffset={scrollOffset}
          rowOnClick={rowOnClick && onClick}
        />
      </div>
      <div className="flex flex-row justify-end pr-2 text-sm italic text-gray-700">
        {rows.length} results
      </div>
    </div>
  )
}

export default FilteredTable
