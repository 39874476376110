import React, { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation, Trans } from 'react-i18next'

import { Button, FilteredTable, SmallButton } from 'components'

import { toLocalTime } from 'helpers/DateTimeHelper'
import CreateTransformModal from './CreateTransformModal'

const ObjectTransformTable = ({ id, ...props }) => {
  //  --- Variables ---
  const refreshRef = useRef()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const onDeleteTransform = async (transformId) => {
    await toast.promise(ApiHelper.deleteObjectTransform(id, transformId), {
      loading: 'Removing transform...',
      success: 'Transform removed',
      error: 'Could not remove transform.',
    })
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const onSubmitTransform = async (transform) => {
    try {
      await toast.promise(ApiHelper.createObjectTransform(id, transform), {
        loading: 'Adding transform...',
        success: 'Transform Added.',
        error: 'Could not add transform.',
      })
      setVisible(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  //  --- Response ---
  return (
    <>
      <FilteredTable
        apiName={[`indexObjectTransforms`]}
        dataApi={() => ApiHelper.indexObjectTransforms(id)}
        filterDebounce={true}
        initialSort={{ id: 'transformationId', desc: true }}
        placeholder={t('application:search')}
        removeFooter={true}
        refreshRef={refreshRef}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onDeleteTransform)}
        queryProps={props.queryProps}
      >
        <Button onClick={(e) => setVisible(true)}>Add</Button>
      </FilteredTable>
      {visible && (
        <CreateTransformModal
          onCancel={(e) => setVisible(false)}
          onSubmit={onSubmitTransform}
        />
      )}
    </>
  )
}

const tableHeaders = (onDelete) => [
  // {
  //   Header: 'ID',
  //   accessor: 'transformationId',
  //   width: 100,
  // },
  {
    Header: 'Transform Name',
    accessor: 'telemetryValue',
    width: 150,
  },
  {
    Header: 'Transform',
    accessor: 'transform',
    width: 250,
  },
  {
    Header: 'Created Time',
    accessor: 'createdDate',
    width: 180,
    Cell: (props) => toLocalTime(props.row.original.createdDate),
  },
  {
    Header: 'Actions',
    i18n: 'indexObjects:objectGroupsTable.actions',
    accessor: 'nothing',
    width: 250,
    Cell: (props) => {
      return (
        <SmallButton
          onClick={(e) => onDelete(props.row.original.transformationId)}
        >
          <Trans i18nKey="application:delete"></Trans>
        </SmallButton>
      )
    },
  },
]

export default ObjectTransformTable
