import React from 'react'
import Button from './Button'
import './Button.css'

const PillButton = ({ children, className, selected, ...props }) => {
  return (
    <Button
      className={`rounded-xl border-2 text-sm font-semibold text-gray-800 md:rounded-b-none md:border-none 2xl:text-base ${
        selected ? 'bg-white-important shadow-lg md:shadow-none' : ''
      } ${className}`}
      {...props}
    >
      {children}
    </Button>
  )
}

export default PillButton
