import React from 'react'

const ShimmerCard = ({ columnCount, showCircle, ...props }) => {
  return (
    <div className="flex flex-row">
      <div
        className={`mr-5 h-12 w-12 animate-pulse rounded-full bg-gray-300 ${
          showCircle ? '' : 'hidden'
        }`}
      />
      <div className="flex w-full flex-col space-y-5">
        {columnCount > 0
          ? Array.from(Array(columnCount), (e, i) => {
              return (
                <div
                  className="flex flex-col space-y-2"
                  key={`shimmer_card_${i}`}
                >
                  <div className="h-4 w-3/4 animate-pulse rounded bg-gray-300" />
                  <div className="space-y-2">
                    <div className="h-4 animate-pulse rounded bg-gray-300" />
                    <div className="h-4 w-5/6 animate-pulse rounded bg-gray-300" />
                  </div>
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default ShimmerCard
