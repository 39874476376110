import React, { useCallback, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ReactTable, Spinner, TextInput } from 'components'
import ApiHelper from 'helpers/ApiHelper'

const GroupSearchTable = ({ id, onSubmit }) => {
  //  --- Variables ---
  const debounceRef = useRef()
  const [inputValue, setInputValue] = useState('')
  const [queryValue, setQueryValue] = useState('')
  const { data, isFetching } = useQuery(['searchGroups', queryValue], () =>
    ApiHelper.searchGroups(queryValue)
  )

  //  --- Functionality ---
  const updateFilterValue = useCallback((e) => {
    const value = e.target.value
    setInputValue(value)

    if (debounceRef.current != null) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      setQueryValue(value)
    }, 500)
  }, [])

  //  --- Response ---
  return (
    <div className="flex h-full flex-col space-y-4">
      <div className="flex flex-row">
        <TextInput
          className="w-full md:w-2/3 xl:w-1/2"
          onChange={updateFilterValue}
          value={inputValue}
          placeholder="Search groups..."
        />
        {isFetching ? <Spinner /> : null}
      </div>
      <ReactTable
        columns={tableHeaders}
        data={data ? data : []}
        height={450}
        initialSort={{ id: 'groupName', desc: true }}
        removeFooter={true}
        rowOnClick={onSubmit}
      />
    </div>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectGroupsTable.id',
  //   accessor: 'groupId',
  //   width: 70,
  // },
  {
    Header: 'Name',
    i18n: 'indexObjects:objectGroupsTable.name',
    accessor: 'groupName',
    width: 240,
  },
  {
    Header: 'Tenant ID',
    i18n: 'indexObjects:objectGroupsTable.tenantId',
    accessor: 'tenantId',
    width: 120,
  },
]

export default GroupSearchTable
