import React from 'react'
import ExternalAuth from 'containers/SolverAdmin/Forms/ExternalAuth'

const ExternalAuthTab = ({ id }) => {
  return (
    <div className="mb-5">
      <ExternalAuth id={id} />
    </div>
  )
}

export default ExternalAuthTab
