import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation, Trans } from 'react-i18next'

import { Icon } from '../'

import ApiHelper from 'helpers/ApiHelper'

const ObjectActiveSessions = ({ id, from, to, open }) => {
  //  --- Variables ---
  const { data, isLoading } = useQuery(
    ['indexObjectTransactions', id, from, to, open],
    () => ApiHelper.indexObjectTransactions(id, from, to, open)
  )

  //  --- Setup ---
  useTranslation()

  //  --- Response ---
  return (
    <div className="flex flex-row border-b px-2 pb-2 text-lg">
      <Icon iconName="users" />
      {isLoading && data !== null ? '--' : data.length}
      <Trans i18nKey={'showObject:activeSessions'}>{` Active Sessions`}</Trans>
    </div>
  )
}

export default ObjectActiveSessions
