import NumberFormat from 'react-number-format'

const FormatNumber = (props) => {
  const value =
    typeof props.number !== 'number'
      ? isNaN(parseFloat(props.number))
        ? 0
        : parseFloat(props.number)
      : props.number

  return (
    <NumberFormat
      decimalScale={props.decimals == null ? 2 : props.decimals}
      fixedDecimalScale={true}
      value={value}
      thousandSeparator=" "
      displayType="text"
      decimalSeparator={','}
      isNumericString={true}
    />
  )
}

export default FormatNumber
