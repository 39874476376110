import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FlexibleModal, Spinner } from 'components'
import * as yup from 'yup'
import cn from 'classnames'

const validationSchema = () => {
  return yup.object({
    onlinePayment: yup.boolean().default(true).label('Online Payment'),
    canInvoice: yup.boolean().default(false).label('Can Invoice'),
    price: yup
      .number()
      .nullable()
      .transform((_, val) => {
        return val === '' ? null : val
      }),
    invoicePrice: yup
      .number()
      .nullable()
      .transform((_, val) => {
        return val === '' ? null : val
      }),
    onlinePrice: yup
      .number()
      .nullable()
      .transform((_, val) => {
        return val === '' ? null : val
      }),
  })
}

const EditPrice = ({ title, object, onCancel, onSubmit }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const validation = validationSchema()

  const initialValues = useMemo(
    () =>
      object != null
        ? validation.validateSync(object, { stripUnknown: true })
        : null,
    [object, validation]
  )

  // Form
  const [price, setPrice] = useState(initialValues.price)
  const [onlinePayment, setOnlinePayment] = useState(
    initialValues.onlinePayment
  )
  const [onlinePrice, setOnlinePrice] = useState(initialValues.onlinePrice)
  const [canInvoice, setCanInvoice] = useState(initialValues.canInvoice)
  const [invoicePrice, setInvoicePrice] = useState(initialValues.invoicePrice)

  const isSubmitting = false // TODO

  const isPriceEmpty = price === '' || !price || isNaN(price)

  const isValid = isPriceEmpty
    ? true
    : parseFloat(canInvoice ? invoicePrice : '0') +
        parseFloat(onlinePayment ? onlinePrice : '0') ===
      parseFloat(price)

  const handleSubmit = () => {
    let body

    if (isPriceEmpty) {
      body = {
        canInvoice: false,
        onlinePayment: false,
        onlinePrice: null,
        invoicePrice: null,
        price: null,
        subscriptionExpiry: object?.subscriptionExpiry,
      }
    } else {
      body = {
        canInvoice,
        onlinePayment,
        onlinePrice: onlinePayment ? onlinePrice : null,
        invoicePrice: canInvoice ? invoicePrice : null,
        price,
        subscriptionExpiry: object?.subscriptionExpiry,
      }
    }

    onSubmit(body)
  }

  if (!initialValues) return <Spinner />

  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="border-b border-gray-200 font-bold">{title}</div>
      <div className="grid grid-cols-4 gap-y-2 py-4">
        {/* Price  */}
        <>
          <div className="col-span-1">
            <Label>Price</Label>
          </div>

          <div className="col-span-3">
            <Input
              type="number"
              name="price"
              value={price}
              className={!isValid ? 'border-red-600' : ''}
              onChange={(e) => {
                const newPrice = parseFloat(e.target.value)

                if (onlinePayment && !canInvoice) {
                  setOnlinePrice('' + newPrice)
                }

                setPrice('' + newPrice)
              }}
            />
          </div>
        </>
        {/* Online payment  */}
        <>
          <div className="col-span-1">
            <Label>Online payment</Label>
          </div>
          <div className="col-span-3 flex flex-row space-x-3">
            <Input
              type="checkbox"
              checked={onlinePayment}
              onChange={(e) => {
                setOnlinePayment(e.target.checked)
                if (e.target.checked === false) {
                  setOnlinePrice('')
                }
              }}
            />
            <Input
              value={onlinePrice}
              type="number"
              onChange={(e) => {
                let val = parseFloat(e.target.value)
                const p = parseFloat(price)

                if (val < 0) {
                  val = 0
                }

                if (onlinePayment && canInvoice) {
                  if (val <= p && val >= 0) {
                    const difference = p - val
                    setInvoicePrice('' + difference)
                  }
                }

                setOnlinePrice('' + val)
              }}
            />
          </div>
        </>
        {/* Invoice payment  */}
        <>
          <div className="col-span-1">
            <Label>Invoice payment</Label>
          </div>
          <div className="col-span-3 flex flex-row space-x-3">
            <Input
              type="checkbox"
              checked={canInvoice}
              onChange={(e) => {
                setCanInvoice(e.target.checked)
                if (e.target.checked === false) {
                  setInvoicePrice('')
                }
              }}
            />
            <Input
              value={invoicePrice}
              type="number"
              onChange={(e) => {
                let val = parseFloat(e.target.value)
                if (val < 0) {
                  val = 0
                }
                setInvoicePrice('' + val)
              }}
            />
          </div>
        </>
      </div>

      <div className="flex flex-row justify-end space-x-4">
        <span className="flex flex-row space-x-2">
          <Button
            className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            {t('application:cancel')}
          </Button>
          <Button
            disabled={isSubmitting || !isValid}
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={handleSubmit}
          >
            {t('application:submit')}
          </Button>
        </span>
      </div>
    </FlexibleModal>
  )
}

export default EditPrice

const Label = ({ children, ...props }) => {
  return (
    <label className="my-auto" {...props}>
      {children}
    </label>
  )
}

const Input = ({ type, className, ...props }) => {
  return (
    <input
      className={cn(
        type === 'checkbox' ? 'w-6' : 'w-full',
        `focus:outline-none flex h-9 rounded-lg border px-3 focus:border-blue-200 disabled:bg-blue-200 `,
        className
      )}
      type={type || 'text'}
      {...props}
    />
  )
}
