import React, { useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import * as OAuth from 'helpers/OAuthHelper'
import toast from 'react-hot-toast'
import GroupSyncPicker from './GroupSyncPicker'
import CreateGroupModal from 'containers/SolverAdmin/Objects/CreateGroupModal'

const AdminTenantGroupsIndexView = () => {
  const refreshRef = useRef()
  const [syncOpen, setSyncOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)

  const { t } = useTranslation()

  const onSync = useCallback(async (chosenGroups) => {
    await toast.promise(ApiHelper.syncGroups(chosenGroups), {
      loading: 'Syncing groups',
      success: <b>Group sync success</b>,
      error: <b>Failed sync</b>,
    })
    if (refreshRef.current) refreshRef.current()
    toggleSync()
  }, [])

  // present modal multi group picker
  const toggleSync = () => {
    setSyncOpen((s) => !s)
  }

  const validate = async () => {
    const tokenValid = await ApiHelper.verifyGraphToken()
    if (!tokenValid.success) {
      OAuth.Popup('/api/graph/oauth/grant', async (data) => {
        await ApiHelper.saveGraphToken(data)
        toggleSync()
      })
    } else {
      toggleSync()
    }
  }

  const onCreateGroup = async (group) => {
    await toast.promise(ApiHelper.createTenantGroup(group), {
      loading: 'Creating group',
      success: <b>Group created</b>,
      error: <b>Failed to create group</b>,
    })
    if (refreshRef.current) refreshRef.current()
    setCreateOpen(false)
  }

  //  --- Response ---
  return (
    <>
      <Card cardType="primary-content" className="mb-4 h-full">
        <FilteredTable
          apiName="indexTenantGroups"
          dataApi={() => ApiHelper.indexTenantGroups()}
          filterCacheKey="indexTenantGroups"
          refreshRef={refreshRef}
          initialSort={{ id: 'groupName', desc: false }}
          placeholder={t('application:search')}
          filterDebounce={true}
          removeFooter={true}
          scrollRestore={true}
          tableHeaders={tableHeaders}
        >
          <Button onClick={validate}>Sync</Button>
          <Button onClick={(e) => setCreateOpen(true)}>Add Group</Button>
        </FilteredTable>
      </Card>
      {syncOpen && <GroupSyncPicker onCancel={toggleSync} onSubmit={onSync} />}
      {createOpen && (
        <CreateGroupModal
          onCancel={(e) => setCreateOpen(false)}
          onSubmit={onCreateGroup}
        />
      )}
    </>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'groupId',
  //   width: 70,
  // },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'groupName',
    width: 300,
  },
  {
    Header: 'SID',
    i18n: 'indexObjects:objectUsersTable.sid',
    accessor: 'sid',
    width: 400,
  },
]

export default AdminTenantGroupsIndexView
