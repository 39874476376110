import React, { useRef, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Card,
  FilteredTable,
  ObjectName,
  MenuButton,
  IndeterminateCheckbox,
  Tag,
  Spinner,
} from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import {
  ObjectActive,
  ObjectOnlineState,
  ObjectPublic,
} from 'components/shared/Objects'

const AdminTenantObjectsView = (props) => {
  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()
  const tableRef = useRef()
  const refreshRef = useRef()
  const tenantId = props.match.params.id

  const { data: tenant } = useQuery(
    ['tenant', tenantId],
    () => ApiHelper.showTenant(tenantId),
    {
      refetchOnWindowFocus: false,
    }
  )

  const onRemoveAccessBulk = useCallback(async () => {
    const objectIds = tableRef.current
      .getSelectedRows()
      .map((r) => r.original.objectId)
    await toast.promise(ApiHelper.bulkRemoveTenantAccess(tenantId, objectIds), {
      loading: 'Removing access...',
      success: 'Access rights updated.',
      error: 'Could not remove access rights.',
    })

    if (refreshRef.current) refreshRef.current()
  }, [tableRef, refreshRef, tenantId])

  //  --- Response ---
  return (
    <div className="flex h-full flex-col">
      <TenantCard tenant={tenant} />
      <Card cardType="primary-content" className="my-4 h-full">
        <FilteredTable
          apiName={['indexTenantObjects', tenantId]}
          dataFormatter={(data) =>
            data.map((row) => ({ ...row, overrideOnClick: [0] }))
          }
          dataApi={() => ApiHelper.showTenantObject(tenantId)}
          queryProps={{
            refetchOnWindowFocus: false,
          }}
          tableRef={tableRef}
          refreshRef={refreshRef}
          filterCacheKey={['indexTenantObjects', tenantId]}
          initialSort={{ id: 'name', desc: false }}
          placeholder={t('application:search')}
          removeFooter={true}
          scrollRestore={true}
          rowOnClick={(row) => {
            prefetchQuery(
              ['object', row.objectId, { accessLevel: 'access' }],
              row
            )
            history.push(`/objects/access/${row.objectId}`)
          }}
          tableHeaders={tableHeaders}
        >
          <MenuButton
            title={t('application:bulk')}
            options={[
              {
                key: 'rm_access',
                title: 'Remove access',
                onClick: onRemoveAccessBulk,
              },
            ]}
          />
        </FilteredTable>
      </Card>
    </div>
  )
}

const TenantCard = ({ tenant }) => {
  return (
    <Card className="mb-4">
      {tenant ? (
        <div className="flex flex-col">
          <div className="flex flex-row">
            <h2 className="text-xl font-semibold ">{tenant.primaryDomain}</h2>
            {tenant.name && (
              <span className="ml-3 text-lg text-gray-600">{tenant.name}</span>
            )}
          </div>
          {/* <h2 className="text-xl font-semibold ">{tenant.primaryDomain}{tenant.name}</h2> */}
          <div className="flex flex-row flex-wrap">
            {/* <Tag property="Tenant ID" value={tenant.tenantId} />
            <Tag property="Admin ID" value={tenant.adminId || '-'} /> */}
            {/* <Tag property="User Role" value={tenant.currentUserRole} /> */}
            <Tag
              property="Online Payment"
              value={tenant.onlinePayment ? 'Yes' : 'No'}
            />
            <Tag property="Price" value={tenant.price || '-'} />
          </div>
          {/* <div className="mt-4 flex flex-col space-y-2">
            <p>
              <span className="text-gray-400">Primary Domain: </span>
              {tenant.primaryDomain}
            </p>
          </div> */}
        </div>
      ) : (
        <div className="flex">
          <Spinner className="my-auto mr-5" />
        </div>
      )}
    </Card>
  )
}

const tableHeaders = [
  {
    id: 'select',
    resizable: false,
    width: 40,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div className="px-1">
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: (props) => {
      console.log(props)
      return (
        <div className="px-1">
          <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
        </div>
      )
    },
  },
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:indexObjectsTable.id',
  //   accessor: 'objectId',
  //   width: 70,
  // },
  {
    Header: 'Name',
    i18n: 'indexObjects:indexObjectsTable.name',
    accessor: 'name',
    width: 300,
    Cell: (props) => {
      return <ObjectName object={props.row.original} />
    },
  },
  {
    Header: 'External ID',
    i18n: 'indexObjects:indexObjectsTable.externalId',
    accessor: 'externalId',
    width: 150,
  },
  {
    Header: 'Active',
    accessor: 'active',
    width: 85,
    Cell: (props) => {
      return <ObjectActive object={props.row.original} />
    },
  },
  {
    Header: 'Public',
    accessor: 'isPublic',
    width: 85,
    Cell: (props) => {
      return <ObjectPublic object={props.row.original} />
    },
  },
  {
    Header: 'State',
    i18n: 'indexObjects:indexObjectsTable.state',
    accessor: 'state',
    width: 85,
    Cell: (props) => {
      return <ObjectOnlineState object={props.row.original} />
    },
  },
]

export default AdminTenantObjectsView
