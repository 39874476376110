import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Card, Spinner } from 'components'
import { toLocalTime } from 'helpers/DateTimeHelper'
import ApiHelper from 'helpers/ApiHelper'
import './UsersShowView.css'

const AccountView = () => {
  //  --- Variables ---
  const { data, isLoading } = useQuery(
    ['showUserDetails'], 
    () => ApiHelper.showUserDetails(),
    {
      refetchOnWindowFocus: false,
    }
  )

  //  --- Response ---
  if (isLoading || data == null)
    return (
      <Card>
        <div className="flex justify-center">
          <Spinner />
        </div>
      </Card>
    )

  return (
    <Card>
      <p className="pb-3 text-2xl font-medium">Account Details</p>
      <table>
        <tbody>
          <tr>
            <td className="font-medium">User ID</td>
            <td>{data.userID}</td>
          </tr>
          <tr>
            <td className="font-medium">Birth Date</td>
            <td>{toLocalTime(data.birthDate, 'DD-MM-YYYY')}</td>
          </tr>
          <tr>
            <td className="font-medium">Country</td>
            <td>{data.country}</td>
          </tr>
          <tr>
            <td className="font-medium">Town</td>
            <td>{data.town}</td>
          </tr>
          <tr>
            <td className="font-medium">Address 1</td>
            <td>{data.address1}</td>
          </tr>
          <tr>
            <td className="font-medium">Address 2</td>
            <td>{data.address2}</td>
          </tr>
          <tr>
            <td className="font-medium">ZIP Code</td>
            <td>{data.zip}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}

export default AccountView
