import React from 'react'

const Button = ({ children, className, onClick, ...props }) => {
  return (
    <button
      className={`focus:outline-none my-auto rounded-md border bg-gray-100 py-1 px-3 hover:bg-gray-300 focus:ring-1 focus:ring-gray-200 ${className} ${
        props.disabled ? 'cursor-not-allowed opacity-70' : ''
      }`}
      onClick={!props.disabled && onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
