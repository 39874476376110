import React, { useMemo } from 'react'
import cn from 'classnames'
import './Card.css'

const Card = ({ children, cardType, className }) => {
  const renderCardType = useMemo(() => {
    if (cardType === 'login') return 'h-auto 2-96  login-card mx-auto'
  }, [cardType])

  return (
    <div
      className={cn(
        `bg-white md:rounded-xl md:shadow-lg ${renderCardType}`,
        className
      )}
    >
      <div
        className={`${
          cardType === 'login'
            ? 'h-full md:px-16 md:py-12'
            : 'h-full p-8 py-0 md:py-8'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export default Card
