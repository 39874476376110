import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import CreateChildObjectModal from 'containers/SolverAdmin/Objects/CreateChildObjectModal'
import { FilteredTable, SmallButton, ObjectOnlineState } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { useHistory } from 'react-router-dom'

const ObjectChildrenTable = ({ id }) => {
  //  --- Variables ---
  const refreshRef = useRef()
  const history = useHistory()
  const { t } = useTranslation()
  //  --- Response ---

  const onDelete = async (objectId) => {
    await toast.promise(ApiHelper.destroyChildObjects(objectId), {
      success: 'Child object deleted',
      loading: 'Deleting child object',
      error: 'Failed to delete child object',
    })

    refreshRef.current()
  }

  return (
    <FilteredTable
      apiName={['indexChildObjects', id]}
      dataApi={() => ApiHelper.indexChildObjects(id)}
      dataFormatter={(data) =>
        data.map((d) => ({ ...d, overrideOnClick: [6] }))
      }
      queryProps={{ refetchOnWindowFocus: false }}
      rowOnClick={(row) => history.push(`/objects/delegate/${row.objectId}`)}
      filterDebounce={true}
      placeholder={t('application:search')}
      initialSort={{ id: 'name', desc: true }}
      refreshRef={refreshRef}
      removeFooter={true}
      showRefreshButton={true}
      tableHeaders={tableHeaders(onDelete)}
    >
      <CreateChildObjectModal id={id} refetch={refreshRef.current} />
    </FilteredTable>
  )
}

const tableHeaders = (onDelete) => [
  // {
  //   Header: 'ID',
  //   // i18n: 'showObject:tabs.schedulesTab.scheduleId',
  //   accessor: 'objectId',
  //   width: 80,
  // },
  {
    Header: 'Name',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'name',
    width: 280,
  },
  {
    Header: 'External ID',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'externalId',
    width: 160,
  },
  {
    Header: 'State',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'state',
    width: 85,
    Cell: (props) => {
      return <ObjectOnlineState object={props.row.original} />
    },
  },
  {
    Header: 'Delegation Level',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'delegationLevel',
    width: 280,
  },
  {
    Header: 'Delegated By',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: 'delegatedBy',
    width: 280,
  },
  {
    Header: 'Actions',
    // i18n: 'showObject:tabs.schedulesTab.commandName',
    accessor: '',
    width: 120,
    Cell: (props) => {
      return (
        <SmallButton onClick={(e) => onDelete(props.row.original.objectId)}>
          <Trans i18nKey="application:delete"></Trans>
        </SmallButton>
      )
    },
    // Cell: (props) => {
    //   return <Button onClick={onClick}>Remove</Button>
    // },
  },
]
export default ObjectChildrenTable
