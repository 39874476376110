import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Button, DateInput, FlexibleModal, Spinner } from 'components'

const validationSchema = yup.object({
  subscriptionExpiry: yup
    .string()
    .nullable()
    .transform((_, val) => {
      return val === '' || val === null ? null : val
    }),
})

const SubscriptionExpiry = ({ title, object, onCancel, onSubmit }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const initialValues = useMemo(
    () =>
      object != null
        ? validationSchema.validateSync(object, { stripUnknown: true })
        : null,
    [object]
  )

  /**
   * Why was there no Z to start with?
   */
  const [expiryDateTime, setExpiryDateTime] = useState(
    initialValues.subscriptionExpiry
  )

  const onSave = () => {
    onSubmit(
      object,
      validationSchema.cast({ subscriptionExpiry: expiryDateTime })
    )
  }

  if (!initialValues) return <Spinner />

  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="flex-wrap space-y-4 overflow-auto">
        <div className="border-b border-gray-200 font-bold">{title}</div>
        <form>
          <div className="flex items-center space-x-4 pl-2">
            <span>Expiry</span>
            <div>
              <DateInput
                initialValue={expiryDateTime}
                onChange={(nextDate) => setExpiryDateTime(nextDate)}
                dateFormat="yyyy-MM-dd HH:mm"
              />
            </div>
          </div>
        </form>
        <div className="flex flex-row justify-end space-x-4">
          <span className="flex flex-row space-x-2">
            <Button
              className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
              disabled={false}
              onClick={onCancel}
            >
              {t('application:cancel')}
            </Button>
            <Button
              disabled={false}
              className="bg-green-400 text-white hover:bg-green-500"
              onClick={onSave}
            >
              {t('application:submit')}
            </Button>
          </span>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default SubscriptionExpiry
