import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../'

const LanguageSwitch = () => {
  //  --- Variables ---
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'))

  //  --- Functionality ---
  const toggleLanguage = useCallback(
    (lang) => {
      if (lang === 'en') {
        setLanguage('no')
        i18n.changeLanguage('no')
      } else {
        setLanguage('en')
        i18n.changeLanguage('en')
      }
    },
    [i18n, setLanguage]
  )

  //  --- Response ---
  return (
    <button
      className="focus:outline-none h-10 rounded-lg border bg-green-400 px-2 text-center text-white hover:bg-green-500"
      onClick={() => toggleLanguage(language)}
    >
      <div className="flex flex-row">
        <Icon color="text-white" iconName="globeAlt" className="my-auto" />
        <span className="font-semibold">{language.toUpperCase()}</span>
      </div>
    </button>
  )
}

export default LanguageSwitch
