import application from './application.json'
import createObject from './createObject.json'
import indexObjects from './indexObjects.json'
import layout from './layout.json'
import showObject from './showObject.json'
import survey from './survey.json'

const en = {
  application,
  createObject,
  indexObjects,
  layout,
  showObject,
  survey,
}

export default en
