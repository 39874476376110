const UserTypeIcon = ({ userTypeId }) => (
  <div className={`mr-2 rounded`} style={{ minWidth: 16 }}>
    <img
      alt={`User type icon`}
      className="m-1"
      style={{ width: 18, height: 18 }}
      src={`https://api365.solver.no/api/Resource/UserIcon/${userTypeId}/Image`}
    />
  </div>
)

//   if (userTypeId === 1) {
//     return (
//       <div className="flex h-full items-center justify-center">
//         <img
//           className="h-4 w-4"
//           src="/icons/microsoft_icon.png"
//           alt="Microsoft User"
//         />
//       </div>
//     )
//   } else if (userTypeId === 2) {
//     return (
//       <div className="flex h-full items-center justify-center">
//         <img className="h-4 w-4" src="/icons/vipps_mark.png" alt="Vipps User" />
//       </div>
//     )
//   } else if (userTypeId === 4) {
//     return (
//       <div className="flex h-full items-center justify-center">
//         <img
//           className="h-4 w-4"
//           src="/icons/mobile-screen-button-solid.png"
//           alt="Mobile User"
//         />
//       </div>
//     )
//   } else {
//     return (
//       <div className="flex h-full items-center justify-center text-gray-600">
//         <img
//           className="h-4 w-4"
//           src="/icons/clock-regular.png"
//           alt="User Type Pending"
//         />
//       </div>
//     )
//   }
// }

export default UserTypeIcon
