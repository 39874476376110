import React from 'react'
import { Icon } from '../'

const ObjectOnlineState = ({ object }) => {
  if (!object.state) return null

  return (
    <div className="flex items-center text-sm font-medium">
      {object.state !== 3 && (
        <Icon
          iconName="globe"
          color={
            object.state === 1
              ? 'text-green-400'
              : object.state === 2
              ? 'text-red-400'
              : 'text-black'
          }
        />
      )}
    </div>
  )
}

export default ObjectOnlineState
