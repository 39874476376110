import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import { useTranslation, Trans } from 'react-i18next'
import { UserSearchTable } from 'containers'
import { SmallButton, Button, FilteredTable, FlexibleModal } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { toLocalTime } from 'helpers/DateTimeHelper'
import { queryClient } from 'helpers/QueryHelper'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const ObjectAdministratorTable = ({ id }) => {
  const refreshRef = useRef()
  const [visible, setVisible] = useState(false)
  const [delegationLevel, setDelegationLevel] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    return () =>
      queryClient.cancelQueries([
        'showObjectUsers',
        id,
        { accessLevel: 'delegated' },
      ])
  }, [id])

  const onDeleteAdminAccess = async (userId) => {
    await toast.promise(ApiHelper.destroyObjectUser(id, userId, 'delegated'), {
      loading: 'Removing user...',
      success: 'User removed',
      error: 'Could not remove user.',
    })
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const onUserSelect = (row, scrollOffset, e) => {
    console.log('On user selected ', e.isSelected)
    e.toggleRowSelected()

    setSelectedUser(row)
  }

  const onSubmitAdminAccess = async () => {
    const { userName, userId } = selectedUser
    try {
      await toast.promise(
        ApiHelper.createObjectUser(id, {
          userName,
          userId: userId || 0,
          delegationLevel,
        }),
        {
          loading: 'Adding admin...',
          success: 'Admin added.',
          error: 'Could not add admin user.',
        }
      )
      setVisible(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  return (
    <>
      <FilteredTable
        apiName={['showObjectUsers', id, { accessLevel: 'delegated' }]}
        dataApi={() => ApiHelper.showObjectUsers(id, 'delegated')}
        queryProps={{ refetchOnWindowFocus: false }}
        placeholder={t('application:search')}
        refreshRef={refreshRef}
        filterDebounce={true}
        initialSort={{ id: 'userName', desc: 'true' }}
        removeFooter={true}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onDeleteAdminAccess)}
      >
        <Button onClick={() => setVisible(true)}>Add Admin</Button>
      </FilteredTable>
      <FlexibleModal size="modal-md" visible={visible}>
        <div className="flex flex-col space-y-2">
          <UserSearchTable id={id} onSubmit={onUserSelect} tableHeight={300} />
          <div className="flex flex-row justify-between space-x-4 pt-6">
            <p className="my-auto">Delegation Level:</p>
            <Select
              className="w-3/4"
              options={[
                {
                  value: 1,
                  label: 'Owner',
                },
                {
                  value: 2,
                  label: 'Can Delegate',
                },
                {
                  value: 3,
                  label: 'Cannot Delegate',
                },
              ]}
              formatOptionLabel={(option) => option.label}
              onChange={(option) => setDelegationLevel(option.value)}
              placeholder="Delegation level"
              styles={{ borderRadius: 0.5 }}
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
              onClick={() => setVisible(false)}
            >
              {t('application:cancel')}
            </Button>
            <Button
              className="ml-2 bg-green-400 text-white hover:bg-green-500"
              onClick={onSubmitAdminAccess}
            >
              {t('application:submit')}
            </Button>
          </div>
        </div>
      </FlexibleModal>
    </>
  )
}

const tableHeaders = (onDelete) => [
  // {
  //   Header: 'ID',
  //   i18n: 'showObject:tabs.id',
  //   accessor: 'userId',
  //   width: 80,
  // },
  {
    Header: 'Username',
    i18n: 'showObject:tabs.users.username',
    accessor: 'userName',
    width: 380,
  },
  {
    Header: 'Display Name',
    i18n: 'showObject:tabs.users.displayName',
    accessor: 'displayName',
    width: 250,
  },
  {
    Header: 'Tenant',
    i18n: 'indexObjects:objectUsersTable.tenant',
    accessor: 'tenantName',
    width: 100,
  },
  {
    Header: 'Delegation Level',
    i18n: 'indexObjects:indexAdminObjectsTable.delegation',
    accessor: 'delegationLevel',
    width: 150,
  },
  {
    Header: 'Delegated By',
    i18n: 'indexObjects:indexAdminObjectsTable.delegatedBy',
    accessor: 'delegatedBy',
    width: 200,
  },
  {
    Header: 'Delegated Time',
    i18n: 'indexObjects:indexAdminObjectsTable.delegatedTime',
    accessor: 'delegationTime',
    width: 180,
    Cell: (props) => (
      <span>{toLocalTime(props.row.original.delegationTime)}</span>
    ),
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 60,
    Cell: (props) => (
      <UserTypeIcon userTypeId={props.row.original.userTypeId} />
    ),
  },
  {
    Header: 'Actions',
    i18n: 'indexObjects:objectGroupsTable.actions',
    accessor: 'nothing',
    width: 100,
    Cell: (props) => {
      return (
        <SmallButton onClick={(e) => onDelete(props.row.original.userId)}>
          <Trans i18nKey="application:delete"></Trans>
        </SmallButton>
      )
    },
  },
]

export default ObjectAdministratorTable
