import React, { forwardRef } from 'react'

const TextInput = forwardRef(
  ({ children, className, placeholder, value, ...props }, ref) => {
    return (
      <input
        ref={ref}
        value={value ? value : ''}
        placeholder={placeholder}
        className={`focus:outline-none h-9 rounded-lg border bg-white px-3 focus:border-blue-200 ${className}`}
        {...props}
      >
        {children}
      </input>
    )
  }
)

export default TextInput
