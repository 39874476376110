import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FlexibleModal, TextInput, TextArea } from 'components'
import debounce from 'lodash/debounce'
import ApiHelper from 'helpers/ApiHelper'

const CreateTransformModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation()
  const [form, setForm] = useState({})
  const [validated, setValidated] = useState({})

  const debounceValidate = useCallback(
    debounce(async (value) => {
      const r = await ApiHelper.validateTransformExpression(value)
      setValidated(r)
    }, 1000),
    []
  )

  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-4">
          <label className="flex justify-between">
            <p className="my-auto">Transform Name</p>
            <TextInput
              className="w-2/3"
              onChange={(e) =>
                setForm((f) => ({ ...f, telemetryValue: e.target.value }))
              }
              value={form.telemetryValue}
            />
          </label>
          <label className="flex justify-between">
            <p className="my-auto flex-grow">Transform</p>
            <div className="w-2/3 flex-col">
              <TextArea
                className="w-full"
                rows={5}
                onChange={(e) => {
                  debounceValidate(e.target.value)
                  setValidated({})
                  setForm((f) => ({ ...f, transform: e.target.value }))
                }}
                value={form.transform}
              />
              <div
                className={
                  validated.success ? 'text-green-400' : 'text-red-500'
                }
              >
                {validated.message}{' '}
              </div>
            </div>
          </label>
        </div>
        <div className="flex flex-row justify-end space-x-4">
          <Button
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={onCancel}
          >
            {t('application:cancel')}
          </Button>
          <Button
            disabled={!validated.success}
            className="bg-green-400 text-white hover:bg-green-500"
            onClick={(e) => onSubmit(form)}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default CreateTransformModal
