import React, { useCallback, useEffect, useState } from 'react'
import { PillButton } from '../'
import { useTranslation, Trans } from 'react-i18next'
import { useQueryParam } from 'hooks/useQueryParam'

const TabContent = ({ component, onMount = () => {}, ...props }) => {
  const Content = component

  useEffect(() => {
    onMount()
  }, [])

  return <Content {...props} />
}

const TabbedCard = ({ children, className }) => {
  //  --- Variables ---
  useTranslation()
  const [currentTab, setCurrentTab] = useQueryParam('tab', children[0].key)

  // we need to keep track of preloaded tabs, otherwise async changes in tab content kan cause unintended "jumping" between tabs
  const [preloadedTabs, setPreloadedTabs] = useState([])

  const onTabMount = useCallback(
    (tabKey) => {
      setPreloadedTabs((p) => [...new Set([...p, tabKey])])
    },
    [setPreloadedTabs]
  )

  //  --- Response ---
  return (
    <div className={`flex flex-col md:rounded-2xl md:shadow-xl ${className}`}>
      <div className="mb-4 flex flex-wrap overflow-x-auto px-8 md:mb-0 md:flex-nowrap md:rounded-t-2xl md:bg-gray-200 md:pt-2">
        {children.map((child) => {
          return (
            <PillButton
              className="mr-2 mt-2 whitespace-nowrap md:mt-0"
              key={child.key}
              onClick={() => setCurrentTab(child.key)}
              selected={currentTab === child.key}
            >
              <Trans i18nKey={child.i18n}>{child.heading}</Trans>
            </PillButton>
          )
        })}
      </div>
      <div className="flex-grow bg-white p-8 md:rounded-b-2xl">
        {children.map((child) => {
          // const Content = child.component
          return (
            (currentTab === child.key ||
              (child.preload && !preloadedTabs.includes(child.key))) && (
              <div
                key={`${child.key}_content`}
                className={
                  currentTab === child.key ? 'h-full' : 'hidden h-full'
                }
              >
                <TabContent
                  {...child.props}
                  onMount={(e) => onTabMount(child.key)}
                  component={child.component}
                />
              </div>
            )
          )
        })}
      </div>
    </div>
  )
}

export default TabbedCard
