import React, { useState } from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'

const CodeEditor = ({ className, placeholder, value, ...props }) => {
  const [annotations, setAnnotations] = useState()

  const onUpdate = (value) => {
    props.onChange({
      target: {
        value: value,
        name: 'blob_editor',
      },
    })

    try {
      JSON.parse(value)
      setAnnotations()
    } catch (e) {
      const lines = value.split(/\r\n|\r|\n/).length - 1
      const err = [{ row: lines, column: 0, type: 'error', text: e.message }]
      setAnnotations(err)
    }
  }

  return (
    <div className={className}>
      <AceEditor
        width={'100%'}
        height={26 * props.rows}
        mode="json"
        showPrintMargin={false}
        placeholder={placeholder}
        theme="textmate"
        value={value}
        setOptions={{ useWorker: false }}
        annotations={annotations}
        onChange={onUpdate}
        name="blob_editor"
      />
    </div>
  )
}

export default CodeEditor
