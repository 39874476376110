import { useState } from 'react'
import toast from 'react-hot-toast'
import { MenuButton } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import CommandParamModal from '../Commands/CommandParamModal'
import CommandResultModal from '../Commands/CommandResultModal'
import { useMutation } from '@tanstack/react-query'

export default function CommandDropdown({ accessLevel, commandMap, id }) {
  //  --- Variables ---
  const [visibleModal, setVisibleModal] = useState()
  const [pendingCommand, setPendingCommand] = useState()
  const [commandResult, setCommandResult] = useState()

  const commandMutation = useMutation({
    mutationFn: ({ command, param }) => {
      return ApiHelper.executeObjectCommand(id, command, accessLevel, param)
    },
  })

  const handleBookMutation = useMutation({
    mutationFn: () => {
      const guid = ApiHelper.createSignedUrl(id)

      // Redirect to booking page
      window.open(`https://book.solver.no/booking/${guid}`)
    },
  })

  const executeCommand = async (command, param) => {
    await toast.promise(
      // ApiHelper.executeObjectCommand(id, command, accessLevel, param),
      commandMutation.mutateAsync({ command, param }),
      {
        loading: 'Running command',
        success: (data) => {
          setCommandResult(data)
          return <b>Command success</b>
        },
        error: <b>Command failed</b>,
      }
    )
    setPendingCommand(null)
    setVisibleModal(null)
  }

  const commandClick = (command) => {
    // Check for client only commands first
    if (command.native.toLowerCase() === 'book_planyo') {
      handleBookMutation.mutate()
      return
    }

    if (command.input) {
      // Prompt for param first
      setPendingCommand(command)
      setVisibleModal('param')
      return
    }

    executeCommand(command.native, null)
  }

  return (
    <>
      <MenuButton
        title={commandMutation.isLoading ? 'Executing... ' : 'Execute'}
        isLoading={commandMutation.isLoading}
        options={commandMap.map((command) => ({
          key: command.native,
          title: command.display,
          description: command.label ?? undefined,
          onClick: () => {
            commandClick(command)
          },
        }))}
      />
      {visibleModal === 'param' && pendingCommand && (
        <CommandParamModal
          label={pendingCommand.label}
          setIsVisible={setVisibleModal}
          executeCommand={async (param) => {
            await executeCommand(pendingCommand.native, param)
            setVisibleModal(null)
          }}
        />
      )}
      {commandResult && (
        <CommandResultModal
          data={commandResult}
          onClose={() => setCommandResult(null)}
        />
      )}
    </>
  )
}
