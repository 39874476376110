import React, { useMemo } from 'react'
import { GlobeAmericasIcon } from '@heroicons/react/24/solid'

const ObjectIsOnline = ({ object }) => {
  //  --- Variables ---
  const color = useMemo(() => {
    if (object.online) return 'green'
    return 'red'
  }, [object])

  //  --- Response ---
  return (
    <div
      className={`bg-${color}-100 border border-${color}-300 my-auto flex h-9 space-x-2 rounded-md px-3`}
    >
      <span className="flex flex-col justify-center">
        <GlobeAmericasIcon className={`h-5 w-5 text-${color}-500`} />
      </span>
      <div
        className={`hidden flex-col justify-center text-${color}-900 md:flex`}
      >
        <span>
          {color === 'red'
            ? 'Offline'
            : color === 'green'
            ? 'Online'
            : 'Unknown'}
        </span>
      </div>
    </div>
  )
}

export default ObjectIsOnline
