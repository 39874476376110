import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Select from 'react-select'
import { Card, FlexibleModal, FilteredTable, Button, Icon } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import * as OAuth from 'helpers/OAuthHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import toast from 'react-hot-toast'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const GroupPickerModal = (props) => {
  const [groups, setGroups] = useState()
  const [group, setGroup] = useState()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    const getGroups = async () => {
      const gr = await ApiHelper.indexTenantGroups()
      const mappedG = gr.map((g) => ({ value: g.sid, label: g.groupName }))
      setGroups(mappedG)
    }

    toast.loading('Loading groups')
    getGroups()
      .then(() => {
        toast.dismiss()
      })
      .catch(() => {
        toast.error('Failed')
      })
  }, [])

  if (groups == null) return null

  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between space-x-4 pt-6">
          <p className="my-auto">Group</p>
          <Select
            className="w-3/4"
            options={groups}
            formatOptionLabel={(option) => option.label}
            onChange={setGroup}
            placeholder="Choose an AD Group"
            styles={{ borderRadius: 0.5 }}
          />
        </div>
        <div className="flex justify-end">
          <Button
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={props.onCancel}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="ml-2 bg-green-400 text-white hover:bg-green-500"
            onClick={() => props.onSubmit(group)}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

const AdminTenantUsersIndexView = () => {
  const [syncOpen, setSyncOpen] = useState(false)
  const [syncResults, setSyncResults] = useState(undefined)

  const refreshRef = useRef()

  //  --- Variables ---
  let history = useHistory()
  const { t } = useTranslation()

  const onSync = useCallback(async () => {
    setSyncOpen(false)
    await toast.promise(ApiHelper.syncGroupUsers(), {
      loading: 'Syncing users',
      success: (data) => {
        setSyncResults(data?.syncLog?.split('\n'))
        return <b>Synced</b>
      },
      error: <b>Failed sync</b>,
    })
    if (refreshRef.current) refreshRef.current()
  }, [setSyncOpen])

  const validate = async () => {
    const tokenValid = await ApiHelper.verifyGraphToken()
    if (!tokenValid.success) {
      OAuth.Popup('/api/graph/oauth/grant', async (data) => {
        await ApiHelper.saveGraphToken(data)
        // setSyncOpen(true)
        onSync()
      })
    } else {
      onSync()
      // setSyncOpen(true)
    }
  }

  //  --- Response ---
  return (
    <>
      <Card cardType="primary-content" className="mb-4 h-full">
        <FilteredTable
          apiName="indexTenantUsers"
          dataApi={() => ApiHelper.indexTenantUsers()}
          filterCacheKey="indexTenantUsers"
          initialSort={{ id: 'displayName', desc: false }}
          placeholder={t('application:search')}
          filterDebounce={true}
          removeFooter={true}
          refreshRef={refreshRef}
          scrollRestore={true}
          rowOnClick={(row) => {
            prefetchQuery(['user', row.sid], row)
            history.push(`/access/users/${row.userId}/${row.sid}`)
          }}
          tableHeaders={tableHeaders}
        >
          <Button onClick={validate}>Sync</Button>
        </FilteredTable>
      </Card>
      {syncResults !== undefined && (
        <SyncResultsModal
          syncResults={syncResults}
          onClose={() => {
            setSyncResults(undefined)
          }}
        />
      )}
      {syncOpen && (
        <GroupPickerModal
          onCancel={(e) => setSyncOpen(false)}
          onSubmit={onSync}
        />
      )}
    </>
  )
}

const SyncResultsModal = ({ syncResults, onClose }) => {
  return (
    <FlexibleModal size="modal-lg" visible={true}>
      <div className="flex flex-col space-y-3">
        <div>
          <h3 className="text-lg font-semibold leading-6 text-gray-900">
            Sync log
          </h3>

          <ul
            style={{
              height: '35rem',
            }}
            className="mt-5 flex flex-col space-y-2 overflow-scroll rounded bg-gray-900 px-3 py-2 font-mono text-sm text-gray-50"
          >
            {syncResults.length > 0 ? (
              <>
                {syncResults.map((log, i) => (
                  <li key={i}>{log}</li>
                ))}
              </>
            ) : (
              <li>No logs found.</li>
            )}
          </ul>
        </div>

        <div className="flex justify-end">
          <Button
            className="ml-2 bg-green-400 text-white hover:bg-green-500"
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'userId',
  //   width: 70,
  // },
  {
    Header: 'Display Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'displayName',
    width: 300,
  },
  {
    Header: 'Username',
    i18n: 'indexObjects:objectUsersTable.username',
    accessor: 'userName',
    width: 400,
  },
  {
    Header: 'Mobile Phone',
    i18n: 'indexObjects:objectUsersTable.mobilePhone',
    accessor: 'mobilePhone',
    width: 180,
  },
  {
    Header: 'Active',
    i18n: 'indexObjects:objectUsersTable.active',
    accessor: 'active',
    width: 75,
    Cell: ({ row }) =>
      row.original.active ? <Icon size={5} iconName="check" /> : '',
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 50,
    Cell: (props) => (
      <UserTypeIcon userTypeId={props.row.original.userTypeId} />
    ),
  },
]

export default AdminTenantUsersIndexView
