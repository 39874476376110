import React, { useCallback, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ReactTable, Spinner, TextInput } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { validateUPN } from 'helpers/ApplicationHelper'

const UserSearchTable = ({ id, onSubmit, tableHeight = 450 }) => {
  //  --- Variables ---
  const debounceRef = useRef()
  const [inputValue, setInputValue] = useState('')
  const [queryValue, setQueryValue] = useState('')
  const { data, isFetching } = useQuery(
    ['searchUsers', queryValue],
    async () => {
      // Encode query value
      const encodedQueryValue = encodeURIComponent(queryValue)
      const users = await ApiHelper.searchUsers(encodedQueryValue)

      const validated = validateUPN(queryValue)
      if (users.length < 1 && validated != null) {
        return [
          {
            displayName: <span>Add '{validated}'</span>,
            userName: validated,
            userId: null,
          },
        ]
      } else {
        return users
      }
    },
    { refetchOnWindowFocus: false, refetchOnMount: false, initialData: [] }
  )

  //  --- Functionality ---
  const updateFilterValue = useCallback((e) => {
    const value = e.target.value
    setInputValue(value)

    if (debounceRef.current != null) {
      clearTimeout(debounceRef.current)
    }

    debounceRef.current = setTimeout(() => {
      setQueryValue(value)
    }, 500)
  }, [])

  //  --- Response ---
  return (
    <div className="flex h-full flex-col space-y-4">
      <div className="flex flex-row">
        <TextInput
          className="w-full md:w-2/3 xl:w-1/2"
          onChange={updateFilterValue}
          value={inputValue}
          placeholder="Search users..."
        />
        {isFetching ? <Spinner /> : null}
      </div>
      <ReactTable
        columns={tableHeaders}
        data={data ? data : []}
        height={tableHeight}
        initialSort={{ id: 'userName', desc: true }}
        removeFooter={true}
        rowOnClick={onSubmit}
      />
    </div>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:objectUsersTable.id',
  //   accessor: 'userId',
  //   width: 70,
  // },
  {
    Header: 'Name',
    i18n: 'indexObjects:objectUsersTable.displayName',
    accessor: 'displayName',
    width: 240,
  },
  {
    Header: 'Username',
    i18n: 'indexObjects:objectUsersTable.userName',
    accessor: 'userName',
    width: 240,
  },
]

export default UserSearchTable
