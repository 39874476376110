import React from 'react'
import { Icon } from '../'

const ObjectLocation = ({ latitude, longitude, className }) => {
  return (
    <a
      className="text-blue-600"
      href={`https://www.google.com/maps/place/${latitude},${longitude}`}
    >
      <Icon
        iconName="locationMarker"
        className={className}
        color="text-blue-600"
      />
    </a>
  )
}

export default ObjectLocation
