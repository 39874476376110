import React from 'react'
import { useTranslation } from 'react-i18next'
import { sortStatusTextByLanguage } from 'helpers/StatusTextHelper'

const ObjectStatusText = ({ object }) => {
  const { i18n } = useTranslation()

  if (!object.statusText) return null

  let label = sortStatusTextByLanguage(
    object.statusText.statusLines,
    i18n.language
  )

  return <span className="text-sm font-medium">{label}</span>
}

export default ObjectStatusText
