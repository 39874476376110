import { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  ReferenceLine,
} from 'recharts'
import { format } from 'date-fns'

const LineGraph = ({
  title,
  data,
  series,
  domain,
  seriesColour,
  min = false,
  max = false,
}) => {
  const CustomTooltip = ({ active, payload }) => {
    let sampleTime = new Date(payload[0]?.payload?.sampleTime ?? undefined)

    if (active && payload && payload.length > 0) {
      return (
        <div className="custom-tooltip rounded-md border border-black bg-white p-4">
          <p>{format(sampleTime, 'yyyy-MM-dd HH:mm:ss')}</p>
          <hr />
          <p className="label">{payload[0].value}</p>
          {minMax.min && <p className="label">Min: {minMax.min}</p>}
          {minMax.max && <p className="label">Max: {minMax.max}</p>}
        </div>
      )
    }
    return null
  }

  const minMax = useMemo(() => {
    let res = {}
    if (min || max) {
      const rows = data.map((r) => r[series])
      if (min) {
        res.min = Math.min(...rows)
      }

      if (max) {
        res.max = Math.max(...rows)
      }
    }

    return res
  }, [data, min, max, series])

  return (
    <div className="flex h-full w-full flex-col items-center">
      <h3 className="mb-4 text-xl font-bold ">{title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={domain}
            tickFormatter={(value) => format(new Date(value), 'dd MMM')}
            interval={1441}
          />

          <YAxis />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomTooltip />}
          />
          <Line
            type="natural"
            dataKey={series}
            stroke={seriesColour}
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
            animationDuration={0}
            activeDot={false}
          />
          {minMax.max ? (
            <ReferenceLine
              y={minMax.max}
              stroke={'red'}
              strokeDasharray="10 10"
            />
          ) : null}
          {minMax.min ? (
            <ReferenceLine
              y={minMax.min}
              stroke={'blue'}
              strokeDasharray="10 10"
            />
          ) : null}
          <Brush
            dataKey={domain}
            height={30}
            stroke={seriesColour}
            tickFormatter={(value) =>
              format(new Date(value), 'yyyy-MM-dd HH:mm')
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineGraph
