import React, { useCallback, useState } from 'react'
import TextInput from './TextInput'
import TextArea from './TextArea'
import CodeEditor from './CodeEditor'

const ComponentMap = {
  input: TextInput,
  textarea: TextArea,
  json: CodeEditor,
}

const TogglableTextInput = ({
  className,
  type = 'input',
  isEditable,
  onChange,
  placeholder,
}) => {
  //  --- Variables ---
  const [displayValue, setDisplayValue] = useState(placeholder)

  //  --- Functionality ---
  const updateValue = useCallback(
    (e) => {
      setDisplayValue(e.target.value)
      if (onChange) onChange(e)
    },
    [setDisplayValue, onChange]
  )

  const Cmp = ComponentMap[type]

  //  --- Response ---
  if (!isEditable) {
    if (type === 'input') {
      return (
        <p className={`h-9 px-1 py-1.5 ${className || ''}`}>{placeholder}</p>
      )
    } else {
      return (
        <pre className={`px-1 py-1.5 ${className || ''}`}>{placeholder}</pre>
      )
    }
  }
  return (
    <Cmp
      className={className}
      onChange={updateValue}
      placeholder={placeholder}
      rows={8}
      value={displayValue}
    />
  )
}

export default TogglableTextInput
