import React from 'react'
import { Field, useField } from 'formik'
import { DateInput } from 'components'
import { joinStrings } from 'helpers/ApplicationHelper'

const componentForType = (type) => {
  switch (type) {
    case 'select':
      return 'select'
    case 'textarea':
      return 'textarea'
    default:
      return 'input'
  }
}

const FormField = ({ name, className = '', options = [], ...props }) => {
  const [field, { initialValue }, { setValue }] = useField({
    name,
    type: props.type || 'text',
  })

  if (props.type === 'date') {
    return (
      <DateInput
        initialValue={initialValue}
        onChange={(nextDate) => setValue(nextDate)}
        {...props}
      />
    )
  }

  return (
    <Field
      className={joinStrings(
        props.type === 'checkbox' ? 'w-9' : 'w-2/3 flex-grow',
        `focus:outline-none flex h-9 rounded-lg border px-3 focus:border-blue-200 disabled:bg-blue-200 ${className}`
      )}
      {...field}
      {...props}
      component={componentForType(props.type)}
    >
      {options.length ? (
        <>
          {options.map((opt) => (
            <option value={opt.value}>{opt.label}</option>
          ))}
        </>
      ) : null}
    </Field>
  )
}

export { FormField }
