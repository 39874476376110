import { useState } from 'react'
import toast from 'react-hot-toast'
import { Button } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import CommandParamModal from '../Commands/CommandParamModal'
import ReactTooltip from 'react-tooltip'
import CommandResultModal from '../Commands/CommandResultModal'

const ObjectCommands = ({ accessLevel, commandMap, id }) => {
  //  --- Variables ---
  const [visibleModal, setVisibleModal] = useState()
  const [pendingCommand, setPendingCommand] = useState()
  const [commandResult, setCommandResult] = useState()

  const handleBook = async () => {
    const guid = await ApiHelper.createSignedUrl(id)

    // Redirect to booking page
    window.open(`https://book.solver.no/booking/${guid}`)
  }

  const executeCommand = async (command, param) => {
    await toast.promise(
      ApiHelper.executeObjectCommand(id, command, accessLevel, param),
      {
        loading: 'Running command',
        success: (data) => {
          setCommandResult(data)
          return <b>Command success</b>
        },
        error: <b>Command failed</b>,
      }
    )
    setPendingCommand(null)
    setVisibleModal(null)
  }

  const commandClick = (command) => {
    // Check for client only commands first
    if (command.native.toLowerCase() === 'book_planyo') {
      handleBook()
      return
    }

    if (command.input) {
      // Prompt for param first
      setPendingCommand(command)
      setVisibleModal('param')
      return
    }
    executeCommand(command.native, null)
  }

  //  --- Response ---
  return (
    <div className="space-y-0.5">
      <div className="flex flex-row space-x-2">
        <p className="my-auto text-sm text-gray-400">Commands</p>
        {/* {accessLevel === 'delegate' ? (
          <Button onClick={() => setVisibleModal('webhook')}>
            + Add Webhook
          </Button>
        ) : null} */}
      </div>
      <div className="flex flex-wrap ">
        {commandMap.map((command, i) => {
          return (
            <div className="mr-2 mt-1.5" key={i}>
              <Button
                data-tip={command.label}
                data-for={command.label}
                className="text-xs 2xl:text-base"
                onClick={() => commandClick(command)}
                key={`${command.display}_button`}
              >
                {command.display}
              </Button>
              <ReactTooltip
                id={command.label}
                place="bottom"
                type="light"
                className="flex justify-center whitespace-nowrap rounded border bg-gray-100 px-2 py-2 opacity-100 shadow transition-all duration-150"
              />
            </div>
          )
        })}
      </div>
      {/* <CreateCommand  Modal
        isVisible={visibleModal === 'webhook'}
        setIsVisible={setVisibleModal}
      /> */}
      {visibleModal === 'param' && pendingCommand && (
        <CommandParamModal
          label={pendingCommand.label}
          setIsVisible={setVisibleModal}
          executeCommand={async (param) => {
            await executeCommand(pendingCommand.native, param)
            setVisibleModal(null)
          }}
        />
      )}
      {commandResult && (
        <CommandResultModal
          data={commandResult}
          onClose={() => setCommandResult(null)}
        />
      )}
    </div>
  )
}

export default ObjectCommands
