import React from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { FlexibleModal, Button } from 'components'
import TenantSearchTable from 'containers/ObjectTables/TenantSearchTable'
import ApiHelper from 'helpers/ApiHelper'

const TenantAccessModal = ({ objectIds, visible, toggle, onDone }) => {
  const { t } = useTranslation()

  const onAddTenantBulk = async (form) => {
    const tenant = {
      tenantId: form.tenantId || 0,
      primaryDomain: form.primaryDomain,
      name: form.tenantId ? form.name : '',
      adminId: form.adminId,
    }

    await toast.promise(ApiHelper.bulkAddTenantAccess(objectIds(), tenant), {
      loading: 'Creating access...',
      success: 'Tenant access updated.',
      error: 'Could not add tenant access.',
    })

    onDone()
  }

  return (
    <FlexibleModal size="modal-md" visible={visible}>
      <div className="flex flex-col space-y-2">
        <TenantSearchTable id="add-access-bulk" onSubmit={onAddTenantBulk} />
        <div className="flex justify-end pt-10">
          <Button onClick={toggle}>{t('application:cancel')}</Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

export default TenantAccessModal
