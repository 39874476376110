import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { sortObjectsWithChildren, tableHeaders } from '../config'
import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'
import { Card, FilteredTable } from 'components'

export default function AdminObjectsIndexView() {
  const { t } = useTranslation()
  const history = useHistory()
  const tableRef = useRef()
  const refreshRef = useRef()

  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName={`favourites`}
        dataFormatter={sortObjectsWithChildren}
        dataApi={() => ApiHelper.indexFavouriteObjects()}
        tableRef={tableRef}
        refreshRef={refreshRef}
        filterCacheKey={`favourites`}
        filterDebounce={true}
        initialSort={{ id: 'name', desc: false }}
        placeholder={t('application:search')}
        removeFooter={true}
        expandable={true}
        scrollRestore={true}
        rowOnClick={(row) => {
          prefetchQuery(
            ['object', row.objectId, { accessLevel: 'access' }],
            row
          )
          history.push(`/objects/favourites/${row.objectId}`)
        }}
        tableHeaders={tableHeaders['access']}
      />
    </Card>
  )
}
