import React, { useState, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { UserSearchTable } from 'containers'
import { MenuButton, Button, FilteredTable, FlexibleModal } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { toLocalTime } from 'helpers/DateTimeHelper'
import EditPrice from '../Forms/EditPrice'
import SubscriptionExpiry from '../Forms/SubscriptionExpiry'
import UserTypeIcon from 'components/shared/Users/UserTypeIcon'

const ObjectUserAccessTable = ({ id }) => {
  const refreshRef = useRef()
  const [visible, setVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [subscribedUser, setSubscribedUser] = useState(null)
  const { t } = useTranslation()

  const deleteUserAccess = async (userId) => {
    await toast.promise(ApiHelper.destroyObjectUser(id, userId, 'access'), {
      loading: 'Removing user...',
      success: 'User removed',
      error: 'Could not remove user.',
    })
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const toggleUserAccess = async (active, userId) => {
    await toast.promise(
      ApiHelper.toggleActivateObjectUser(id, userId, active),
      {
        loading: `${active ? 'Activating' : 'Deactivating'} user access...`,
        success: `User access ${active ? 'activated' : 'deactivated'}`,
        error: `Could not ${active ? 'activate' : 'deactivate'} user access`,
      }
    )
    if (refreshRef.current) {
      refreshRef.current()
    }
  }

  const onUserAccessAction = async (action, user) => {
    switch (action) {
      case 'delete':
        await deleteUserAccess(user.userId)
        return
      case 'activation':
        await toggleUserAccess(!user.active, user.userId)
        return
      case 'price':
        await setSelectedUser(user)
        return
      case 'expiry':
        await setSubscribedUser(user)
        return
      default:
        return
    }
  }

  const onSubmitUserAccess = async ({ userName, userId }) => {
    try {
      await toast.promise(
        ApiHelper.createObjectUser(id, { userName, userId: userId || 0 }),
        {
          loading: 'Adding user...',
          success: 'User Added.',
          error: 'Could not add user.',
        }
      )
      setVisible(false)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  const onEditUserPrice = async (form) => {
    try {
      await toast.promise(
        ApiHelper.updateUserPrice(id, selectedUser.userId, form),
        {
          loading: 'Saving price...',
          success: 'User pricing saved.',
          error: 'Could not save user pricing.',
        }
      )
      setSelectedUser(null)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  const onEditSubscription = async (object, form) => {
    const body = {
      price: object.price,
      onlinePayment: object.onlinePayment,
      canInvoice: object.canInvoice,
      subscriptionExpiry: form.subscriptionExpiry,
      invoicePrice: object.invoicePrice,
      onlinePrice: object.onlinePrice,
    }

    try {
      await toast.promise(
        ApiHelper.updateUserPrice(id, subscribedUser.userId, body),
        {
          loading: 'Saving price...',
          success: 'User pricing saved.',
          error: 'Could not save user pricing.',
        }
      )
      setSubscribedUser(null)
      if (refreshRef.current) {
        refreshRef.current()
      }
    } catch (err) {}
  }

  return (
    <>
      <FilteredTable
        apiName={['showObjectusers', id, { accessLevel: 'access' }]}
        placeholder={t('application:search')}
        queryProps={{ refetchOnWindowFocus: false }}
        dataApi={() => ApiHelper.showObjectUsers(id, 'access')}
        filterDebounce={true}
        refreshRef={refreshRef}
        initialSort={{ id: 'userName', desc: true }}
        removeFooter={true}
        showRefreshButton={true}
        tableHeaders={tableHeaders(onUserAccessAction)}
      >
        <Button onClick={() => setVisible(true)}>Add User</Button>
      </FilteredTable>
      <FlexibleModal size="modal-md" visible={visible}>
        <div className="flex flex-col space-y-2">
          <UserSearchTable id={id} onSubmit={onSubmitUserAccess} />
          <div className="flex justify-end">
            <Button onClick={() => setVisible(false)}>Close</Button>
          </div>
        </div>
      </FlexibleModal>
      {selectedUser && (
        <EditPrice
          showSubExpiry
          title={'User price'}
          object={selectedUser}
          onSubmit={onEditUserPrice}
          onCancel={(e) => setSelectedUser(null)}
        />
      )}
      {subscribedUser && (
        <SubscriptionExpiry
          title={'Subscription expiry'}
          object={subscribedUser}
          onSubmit={onEditSubscription}
          onCancel={(e) => setSubscribedUser(null)}
        />
      )}
    </>
  )
}

const tableHeaders = (onAction) => [
  // {
  //   Header: 'ID',
  //   i18n: 'showObject:tabs.id',
  //   accessor: 'userId',
  //   width: 80,
  // },
  {
    Header: 'Actions',
    i18n: 'indexObjects:objectGroupsTable.actions',
    accessor: 'nothing',
    width: 100,
    Cell: (props) => (
      <MenuButton
        title="More"
        small={true}
        pinRight={false}
        options={[
          {
            key: 'price',
            title: 'Edit price',
            onClick: () => onAction('price', props.row.original),
          },
          {
            key: 'expiry',
            title: 'Subscription expiry',
            onClick: () => onAction('expiry', props.row.original),
          },
          {
            key: 'activation',
            title: props.row.original.active ? 'Deactivate' : 'Activate',
            onClick: () => onAction('activation', props.row.original),
          },
          {
            key: 'delete',
            title: 'Delete',
            onClick: () => onAction('delete', props.row.original),
          },
        ]}
      />
    ),
  },
  {
    Header: 'Active',
    i18n: 'showObject:tabs.active',
    accessor: 'active',
    width: 80,
    Cell: (props) => (
      <CheckCircleIcon
        className={`mx-auto h-5 w-5 ${
          props.row.original.active ? 'text-green-400' : 'text-gray-200'
        }`}
      />
    ),
  },
  {
    Header: 'Type',
    i18n: 'indexObjects:objectUsersTable.type',
    accessor: 'userTypeId',
    width: 60,
    Cell: (props) => (
      <UserTypeIcon userTypeId={props.row.original.userTypeId} />
    ),
  },

  {
    Header: 'Username',
    i18n: 'showObject:tabs.users.username',
    accessor: 'userName',
    // width: 450,
    width: 250,
  },
  {
    Header: 'Display Name',
    i18n: 'showObject:tabs.users.displayName',
    accessor: 'displayName',
    width: 250,
  },

  {
    Header: 'Tenant',
    i18n: 'indexObjects:objectUsersTable.tenant',
    accessor: 'tenantName',
    width: 120,
  },
  {
    Header: 'Price',
    i18n: 'showObject:tabs.price',
    accessor: 'price',
    width: 70,
    Cell: ({ row: { original } }) => (
      <div className="text-right">{original.price}</div>
    ),
  },
  // {
  //   Header: 'Online Payment',
  //   accessor: 'onlinePayment',
  //   width: 150,
  //   Cell: (props) => (props.row.original.onlinePayment ? 'Yes' : 'No'),
  // },
  {
    Header: 'Online Prc',
    accessor: 'onlinePrice',
    width: 100,
    Cell: ({ row: { original } }) => (
      <div className="text-right">
        {original.onlinePayment ? original.onlinePrice : ''}
      </div>
    ),
  },
  // {
  //   Header: 'Can Invoice',
  //   accessor: 'canInvoice',
  //   width: 110,
  //   Cell: (props) => (props.row.original.canInvoice ? 'Yes' : 'No'),
  // },
  {
    Header: 'Invoice Prc',
    accessor: 'invoicePrice',
    width: 110,
    Cell: ({ row: { original } }) => (
      <div className="text-right">
        {original.canInvoice ? original.invoicePrice : ''}
      </div>
    ),
  },
  {
    Header: 'Subscription Expiry',
    accessor: 'subscriptionExpiry',
    width: 180,
    Cell: (props) =>
      props.row.original.subscriptionExpiry
        ? toLocalTime(props.row.original.subscriptionExpiry)
        : '',
  },
  {
    Header: 'Date',
    i18n: 'showObject:tabs.createdBy',
    accessor: 'createdBy',
    width: 280,
    Cell: (props) => props.row.original.createdBy,
  },
  {
    Header: 'Date',
    i18n: 'showObject:tabs.createdAt',
    accessor: 'createdAt',
    width: 180,
    Cell: (props) => toLocalTime(props.row.original.createdAt),
  },
]

export default ObjectUserAccessTable
