import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormBuilder, Spinner } from 'components'
import { Formik } from 'formik'
import * as yup from 'yup'
import ApiHelper from 'helpers/ApiHelper'
import toast from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'

const ExternalAuth = ({ id }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)

  const { data, isLoading, refetch } = useQuery(
    ['object-ext-auth', id],
    () => ApiHelper.showObjectExternalAuth(id),
    {
      refetchOnWindowFocus: false,
    }
  )

  //  --- Functionality ---
  const saveEditedObject = async (values) => {
    let data = {
      ...values,
      provider: parseInt(values.provider),
    }

    await toast.promise(ApiHelper.updateObjectExternalAuth(id, data), {
      loading: 'Updating external auth...',
      success: 'External auth credentials saved.',
      error: 'Could not save auth',
    })
    if (refetch) {
      await refetch()
    }
    setEditing(false)
  }

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <Formik
      initialValues={data}
      validateOnChange
      validationSchema={validation}
      onSubmit={saveEditedObject}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <div className="flex-wrap space-y-2 overflow-auto">
          <div className="flex flex-row justify-end space-x-4">
            {!editing ? (
              <Button primary onClick={(e) => setEditing(true)}>
                {t('application:edit')}
              </Button>
            ) : (
              <>
                <Button
                  className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm()
                    setEditing(false)
                  }}
                >
                  {t('application:cancel')}
                </Button>
                <Button
                  disabled={!isValid || isSubmitting}
                  className="bg-green-400 text-white hover:bg-green-500"
                  onClick={submitForm}
                >
                  {t('application:submit')}
                </Button>
              </>
            )}
          </div>
          <FormBuilder disabled={!editing} schema={extAuthSchema} />
        </div>
      )}
    </Formik>
  )
}

export default ExternalAuth

const validation = yup.object({
  active: yup.boolean().required(),
  provider: yup.string().strip(),
  applicationId: yup.string().strip(),
  userName: yup.string().strip(),
  password: yup.string().strip(),
  companyId: yup.string().strip(),
  authFailureMessage: yup.string().strip(),
  resourceId: yup.string().strip(),
  siteId: yup.string().strip(),
  preOpenMinutes: yup.number().strip(),
  postOpenMinutes: yup.number().strip(),
})

const extAuthSchema = [
  {
    label: 'Active',
    name: 'active',
    type: 'checkbox',
    className: 'max-h-5 my-auto',
  },
  {
    label: 'Provider',
    name: 'provider',
  },
  {
    label: 'Application ID',
    name: 'applicationId',
  },
  {
    label: 'Username',
    name: 'userName',
  },
  {
    label: 'Password',
    name: 'password',
  },
  {
    label: 'Company ID',
    name: 'companyId',
  },
  {
    label: 'Auth Failed Message',
    name: 'authFailureMessage',
  },
  {
    label: 'Resourcce ID',
    name: 'resourceId',
  },
  {
    label: 'Site ID',
    name: 'siteId',
  },
  {
    label: 'Pre Open Minutes',
    name: 'preOpenMinutes',
  },
  {
    label: 'Post Open Minutes',
    name: 'postOpenMinutes',
  },
]
