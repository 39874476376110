import React, { useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'

import { Button, FilteredTable } from 'components'

import { getDate, toLocalTimeWithMilliseconds } from 'helpers/DateTimeHelper'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { BasicDateInput } from 'components/shared/Inputs/DateInput'

const ObjectTelemetryTable = ({ id, ...props }) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  // const [fromDate, setFrom] = useQueryParam('from', getDate('yesterday'))
  // const [toDate, setTo] = useQueryParam('to', getDate())

  const searchParams = new URLSearchParams(search)

  const fromDate = searchParams.get('from') || getDate('yesterday')
  const toDate = searchParams.get('to') || getDate('default')

  // const [tempFromDate, setTempFromDate] = useState('')
  // const [tempToDate, setTempToDate] = useState('')
  const [enabled, setEnabled] = useState(false)

  const dataFormatter = useCallback((data) => {
    return data.map((d) => {
      return {
        ...d,
        sampleTime: toLocalTimeWithMilliseconds(d.sampleTime),
        createdAt: toLocalTimeWithMilliseconds(d.createdAt),
      }
    })
  }, [])

  //  --- Response ---
  return (
    <FilteredTable
      apiName={[`indexObjectTelemetries`, id, fromDate, toDate]}
      dataApi={() => ApiHelper.indexObjectTelemetry(id, fromDate, toDate)}
      filterDebounce={true}
      initialSort={{ id: 'sampleTime', desc: true }}
      placeholder={t('application:search')}
      downloadUrls={{
        json: () => `/api/bridge/${id}/telemetry?from=${fromDate}&to=${toDate}`,
      }}
      removeFooter={true}
      dataFormatter={dataFormatter}
      showRefreshButton={false}
      tableHeaders={tableHeaders()}
      queryProps={{
        ...props.queryProps,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled,
      }}
    >
      <form
        className="flex flex-row space-x-2"
        onSubmit={(e) => {
          e.preventDefault()

          // if (invalidDate) return

          setEnabled(true)

          try {
            // const tfd = new Date(tempFromDate).toISOString()
            // const ttd = new Date(tempToDate).toISOString()
            const tfd = new Date(e.target.elements.fromDate.value).toISOString()
            const ttd = new Date(e.target.elements.toDate.value).toISOString()

            searchParams.set('from', tfd)
            searchParams.set('to', ttd)
            history.replace({ search: searchParams.toString() })
          } catch (error) {
            console.log('DATE ERROR')
          }
        }}
      >
        <BasicDateInput
          name="fromDate"
          defaultValue={new Date(fromDate)}
          // defaultValue="2023-08-17 18:30:00"
          // onChange={(isoString) => {
          //   // setTempFromDate(new Date(isoString).toISOString())
          //   setTempFromDate(isoString)
          // }}
        />
        {/* <DateInput
        // onChange={(d) => setFrom(d)}
        onChange={(maybeDateString) => setTempFromDate(maybeDateString)}
        initialValue={fromDate}
      /> */}
        <ArrowRightIcon className="h-full w-5 p-1" />
        {/* <DateInput
        // onChange={(d) => setTo(d)}
        onChange={(d) => setTempToDate(d)}
        initialValue={toDate}
      /> */}
        <BasicDateInput
          name="toDate"
          defaultValue={new Date(toDate)}
          // defaultValue="2023-08-17 19:00:00"
          // onChange={(isoString) => {
          //   // setTempToDate(new Date(isoString).toISOString())
          //   setTempToDate(isoString)
          // }}
        />
        <Button
          type="submit"
          // disabled={invalidDate}
        >
          Submit
        </Button>
      </form>
    </FilteredTable>
  )
}

const tableHeaders = () => [
  {
    Header: 'ID',
    accessor: 'telemetryId',
    width: 90,
  },
  {
    Header: 'Sample Time',
    accessor: 'sampleTime',
    // accessor: (d) => new Date(d.sampleTime).getTime(),
    width: 220,
    Cell: (props) => props.row.original.sampleTime,
    // props.row.original.sampleTime
    //   ? toLocalTimeWithMilliseconds(props.row.original.sampleTime)
    //   : '',
  },
  {
    Header: 'Created Time',
    accessor: 'createdAt',
    width: 220,
    // Cell: (props) => toLocalTimeWithMilliseconds(props.row.original.createdAt),
  },
  {
    Header: 'Time Offset',
    accessor: 'timeOffset',
    width: 100,
  },
  {
    Header: 'Signal',
    accessor: 'signal',
    width: 70,
  },
  {
    Header: 'Battery',
    accessor: 'battery',
    width: 80,
  },
  {
    Header: 'Temperature',
    accessor: 'temperature',
  },
  {
    Header: 'Adc1',
    accessor: 'adc1',
  },
  {
    Header: 'Adc2',
    accessor: 'adc2',
  },
  {
    Header: 'Adc3',
    accessor: 'adc3',
  },
  {
    Header: 'Adc4',
    accessor: 'adc4',
  },
  {
    Header: 'AccellerationX',
    accessor: 'accellerationX',
  },
  {
    Header: 'AccellerationY',
    accessor: 'accellerationY',
  },
  {
    Header: 'AccellerationZ',
    accessor: 'accellerationZ',
  },
  // {
  //   Header: 'TelemetryID',
  //   accessor: 'telemetryId',
  //   width: 100,
  // },
]

export default ObjectTelemetryTable
