import React, { Suspense, lazy } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Route, Switch } from 'react-router'
import { Toaster } from 'react-hot-toast'
import { GuardedRoute, Layout, Spinner } from './components'
import { queryClient } from 'helpers/QueryHelper'

import { AccountView, AuthenticatedView, LoginView } from './views/shared'
import { AdminQRView } from 'views/SolverAdmin'

import config from './config'
import './i18n'
import './custom.css'

const GreenstatRoutes = lazy(() => import('./portals/Greenstat/routes'))
const SolverVisitRoutes = lazy(() => import('./portals/SolverVisit/routes'))
const SolverAdminRoutes = lazy(() => import('./portals/SolverAdmin/routes'))
const SmartSensorRoutes = lazy(() => import('./portals/SmartSensor/routes'))

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="h-full">
        <Toaster />
        <Switch>
          <Route exact path="/login" component={LoginView} />
          <GuardedRoute
            path="/objects/:id/qr/:command"
            component={AdminQRView}
          />
          <Layout>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route path="/authenticated" component={AuthenticatedView} />
                <GuardedRoute path="/account" component={AccountView} />

                {config.key === 'greenstat' && <GreenstatRoutes />}
                {config.key === 'visit' && <SolverVisitRoutes />}
                {config.key === 'admin' && <SolverAdminRoutes />}
                {config.key === 'sensor' && <SmartSensorRoutes />}
              </Switch>
            </Suspense>
          </Layout>
        </Switch>
      </div>
    </QueryClientProvider>
  )
}

export default App
