import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormBuilder, Spinner } from 'components'
import { Formik } from 'formik'
import * as yup from 'yup'
import ApiHelper from 'helpers/ApiHelper'
import toast from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'

const Geofence = ({ id: objectId }) => {
  //  --- variables ---
  const { t } = useTranslation()

  const { data, isLoading, refetch } = useQuery(
    ['object-geofence', objectId],
    () => ApiHelper.showObjectGeofence(objectId),
    {
      refetchOnWindowFocus: false,
    }
  )

  //  --- Functionality ---
  const saveEditedObject = async (values) => {
    await toast.promise(ApiHelper.updateObjectGeofence(objectId, values), {
      loading: 'Updating geofence...',
      success: 'Geofence saved.',
      error: 'Could not save geofence',
    })
    if (refetch) {
      await refetch()
    }
  }

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <Formik
      initialValues={data}
      validateOnChange
      validationSchema={validation}
      onSubmit={saveEditedObject}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <div className="flex-wrap space-y-2 overflow-auto">
          <div className="flex flex-row justify-between space-x-4 border-b border-gray-200 py-1">
            <span className=" font-bold">Geofence</span>
          </div>
          <FormBuilder schema={geofenceSchema} />
          <div className="flex flex-row justify-end space-x-2">
            <Button
              className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
              disabled={isSubmitting}
              onClick={() => {
                resetForm()
              }}
            >
              {t('application:cancel')}
            </Button>
            <Button
              disabled={!isValid || isSubmitting}
              className="bg-green-400 text-white hover:bg-green-500"
              onClick={submitForm}
            >
              {t('application:submit')}
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default Geofence

const canOverrideOptions = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 2,
    label: 'User',
  },
  {
    value: 3,
    label: 'Everyone',
  },
]

const validation = yup.object({
  active: yup.boolean().default(false).required(),
  canOverride: yup
    .number()
    .oneOf([0, 1, 2, 3])
    .transform((value) => parseInt(value, 10)),
  radius: yup.number().required(),
})

const geofenceSchema = [
  {
    label: 'Active',
    name: 'active',
    type: 'checkbox',
    className: 'max-h-5 my-auto',
  },
  {
    label: 'Can Override',
    name: 'canOverride',
    type: 'select',
    options: canOverrideOptions,
  },
  {
    label: 'Radius',
    name: 'radius',
    type: 'number',
  },
]
