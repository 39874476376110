import React, { useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { FlexibleModal, Button } from 'components'
import { UserSearchTable } from 'containers'
import ApiHelper from 'helpers/ApiHelper'

const UserAdminModal = ({ objectIds, visible, toggle, onDone }) => {
  const { t } = useTranslation()
  const [delegationLevel, setDelegationLevel] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const onAddDelegationBulk = async () => {
    const { userName, userId } = selectedUser

    await toast.promise(
      ApiHelper.bulkAddUserAccess(
        {
          userName,
          userId,
          delegationLevel,
        },
        objectIds()
      ),
      {
        loading: 'Creating admin...',
        success: 'Admin rights updated.',
        error: 'Could not add admin rights.',
      }
    )

    onDone()
  }

  const onUserSelect = (row, scrollOffset, e) => {
    e.toggleRowSelected()
    setSelectedUser(row)
  }

  return (
    <FlexibleModal size="modal-md" visible={visible}>
      <div className="flex flex-col space-y-2">
        <UserSearchTable
          id="add-delegation-bulk"
          onSubmit={onUserSelect}
          tableHeight={300}
        />
      </div>
      {/*  */}
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between space-x-4 pt-6">
          <p className="my-auto">Delegation Level:</p>
          <Select
            className="w-3/4"
            options={[
              {
                value: 1,
                label: 'Owner',
              },
              {
                value: 2,
                label: 'Can Delegate',
              },
              {
                value: 3,
                label: 'Cannot Delegate',
              },
            ]}
            formatOptionLabel={(option) => option.label}
            onChange={(option) => setDelegationLevel(option.value)}
            placeholder="Delegation level"
            styles={{ borderRadius: 0.5 }}
          />
        </div>
        <div className="flex justify-end">
          <Button
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
            onClick={toggle}
          >
            {t('application:cancel')}
          </Button>
          <Button
            className="ml-2 bg-green-400 text-white hover:bg-green-500"
            onClick={onAddDelegationBulk}
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
      {/*  */}
    </FlexibleModal>
  )
}

export default UserAdminModal
