import React from 'react'
import Header from './Header'
import Navbar from './Navbar'

export default function Layout({ children }) {
  return (
    <div id="layout" className={`flex h-full flex-col md:flex-row`}>
      <Navbar />

      <div
        id="body"
        className="flex-grow overflow-hidden md:mx-auto md:w-full md:flex-grow-0"
      >
        <div className="mx-auto flex h-screen max-h-screen flex-col md:mx-10">
          <Header />
          {children}
        </div>
      </div>
    </div>
  )
}
