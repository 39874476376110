import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilteredTable } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { toLocalTime } from 'helpers/DateTimeHelper'

const ObjectStatusTable = ({ id }) => {
  //  --- Variables ---
  const [tableHeaders, setTableHeaders] = useState()
  const { t } = useTranslation()

  //  --- Functionality ---
  const dataFormatter = useCallback(
    (data) => {
      var response = []
      var headerWidths = [190, 100]
      var headers = {
        time: 'Time',
        success: 'Success',
      }
      var newHeaders = [...defaultTableHeaders]

      data.forEach((status) => {
        let newStatus = {
          time: toLocalTime(status.time),
          success: status.success.toString(),
        }

        for (let i = 0; i < status.context.length; i++) {
          let statusKey = status.context[i].key
          let statusValue = status.context[i].value
          if (headers[statusKey] == null) {
            headers[statusKey] = status.context[i].label
            if (statusValue) {
              headerWidths.push(
                Math.max(
                  9.0 * statusValue.length + 42.0,
                  9.0 * statusKey.length + 42.0
                )
              )
            } else {
              headerWidths.push(9.0 * statusKey.length + 42.0)
            }
          }
          newStatus[statusKey] = statusValue
        }

        response.push(newStatus)
      })

      const headerKeys = [...Object.keys(headers)]
      console.log(headerKeys)
      for (let i = 2; i < headerKeys.length; i++) {
        newHeaders.push({
          Header: headers[headerKeys[i]],
          accessor: headerKeys[i],
          width: headerWidths[i],
        })
      }

      setTableHeaders(newHeaders)

      return response
    },
    [setTableHeaders]
  )

  const headers = useCallback(
    () => tableHeaders || defaultTableHeaders,
    [tableHeaders]
  )

  //  --- Response ---
  return (
    <FilteredTable
      apiName={['showObjectStatus', id]}
      placeholder={t('application:search')}
      dataFormatter={dataFormatter}
      queryProps={{ refetchOnWindowFocus: false }}
      dataApi={() => ApiHelper.showObjectStatus(id)}
      filterDebounce={true}
      initialSort={{ id: 'time', desc: 'true' }}
      removeFooter={true}
      showRefreshButton={true}
      tableHeaders={headers()}
    />
  )
}

const defaultTableHeaders = [
  {
    Header: 'ID',
    i18n: 'showObject:tabs.time',
    accessor: 'time',
    width: 190,
    Cell: (props) => <span>{props.row.original.time}</span>,
  },
  {
    Header: 'Success',
    i18n: 'showObject:tabs.statusTab.success',
    accessor: 'success',
    width: 100,
  },
]

export default ObjectStatusTable
