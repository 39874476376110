import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormBuilder, Spinner } from 'components'
import { Formik } from 'formik'
import * as yup from 'yup'
import ApiHelper from 'helpers/ApiHelper'
import toast from 'react-hot-toast'

const Subscription = ({ object }) => {
  //  --- variables ---
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)

  const initialValues = useMemo(
    () => validation.validateSync(object, { stripUnknown: true }),
    [object]
  )

  //  --- Functionality ---
  const saveEditedObject = async (values) => {
    await toast.promise(
      ApiHelper.updateObject(object.objectId, { ...object, ...values }),
      {
        loading: 'Updating subscription info...',
        success: 'Subscription info saved',
        error: 'Could not save subscription info',
      }
    )
    setEditing(false)
  }

  if (!initialValues) return <Spinner />

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validationSchema={validation}
      onSubmit={saveEditedObject}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <div className="flex-wrap space-y-2 overflow-auto">
          <div className="flex flex-row justify-between space-x-4 border-b border-gray-200 py-1">
            <span className=" font-bold">Subscription</span>
            {!editing ? (
              <Button primary onClick={(e) => setEditing(true)}>
                {t('application:edit')}
              </Button>
            ) : (
              <span className="flex flex-row space-x-2">
                <Button
                  className="border border-red-200 text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm()
                    setEditing(false)
                  }}
                >
                  {t('application:cancel')}
                </Button>
                <Button
                  disabled={!isValid || isSubmitting}
                  className="bg-green-400 text-white hover:bg-green-500"
                  onClick={submitForm}
                >
                  {t('application:submit')}
                </Button>
              </span>
            )}
          </div>
          <FormBuilder disabled={!editing} schema={schema} />
        </div>
      )}
    </Formik>
  )
}

export default Subscription

const validation = yup.object({
  hasSubscription: yup.boolean().default(false),
})

const schema = [
  {
    label: 'Has Subscription',
    name: 'hasSubscription',
    type: 'checkbox',
    className: 'max-h-5 my-auto',
  },
]
