import React from 'react'
import cn from 'classnames'

const TimeInput = ({ onChange, isInvalid, className }) => {
  const now = new Date()

  // const defaultValue = dayjs(now).format('HH:mm')

  return (
    <input
      className={cn(
        `rounded border-2 px-2 py-2`,
        isInvalid ? 'border-red-400' : 'border-gray-300',
        className
      )}
      placeholder={`HH:mm`}
      onChange={(e) => {
        const newDate =
          e.target.value === ''
            ? `${now.getHours()}:${now.getMinutes()}`
            : e.target.value

        onChange(newDate)
      }}
    />
  )
}

export default TimeInput
