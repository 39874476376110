const Greenstat = {
  key: 'greenstat',
  appName: 'Greenstat',
  footer: [
    {
      href: '/settings',
      i18n: 'layout:sidebarItems.settings',
      linkType: 'navLink',
      icon: '',
    },
  ],
  menu: [
    {
      href: '/home',
      i18n: 'layout:sidebarItems.forms',
      linkType: 'navLink',
      icon: 'cube',
    },
    {
      href: '/userLogs',
      i18n: 'layout:sidebarItems.logs',
      linkType: 'navLink',
      icon: 'menu',
    },
  ],
}

const SolverAdmin = {
  key: 'admin',
  appName: 'Solver Admin',
  footer: [
    {
      href: '/settings',
      i18n: 'layout:sidebarItems.settings',
      linkType: 'navLink',
      icon: '',
    },
    {
      href: '/privacy',
      i18n: 'layout:sidebarItems.privacy',
      linkType: 'navLink',
      icon: '',
    },
  ],
  menu: [
    {
      i18n: 'layout:sidebarItems.objects',
      submenu: [
        {
          i18n: 'layout:sidebarItems.userFavourites',
          href: '/objects/favourites',
          icon: 'star',
          linkType: 'navLink',
        },
        {
          i18n: 'layout:sidebarItems.findObjects',
          href: '/objects/find',
          icon: 'search',
          linkType: 'navLink',
        },
        {
          i18n: 'layout:sidebarItems.accessObjects',
          href: '/objects/access',
          icon: 'cube',
          linkType: 'navLink',
        },
        {
          i18n: 'layout:sidebarItems.delegatedObjects',
          href: '/objects/delegate',
          icon: 'cube',
          linkType: 'navLink',
        },
      ],
    },
    {
      i18n: 'layout:sidebarItems.access',
      submenu: [
        {
          href: '/access/users',
          i18n: 'layout:sidebarItems.users',
          linkType: 'navLink',
          icon: 'user',
        },
        {
          href: '/access/groups',
          i18n: 'layout:sidebarItems.groups',
          linkType: 'navLink',
          icon: 'users',
        },
        {
          href: '/access/tenants',
          i18n: 'layout:sidebarItems.tenants',
          linkType: 'navLink',
          icon: 'globe',
        },
      ],
    },
    {
      i18n: 'layout:sidebarItems.myOwnTenant',
      submenu: [
        {
          href: '/tenant/users',
          i18n: 'layout:sidebarItems.myUsers',
          linkType: 'navLink',
          icon: 'user',
        },
        {
          href: '/tenant/groups',
          i18n: 'layout:sidebarItems.myGroups',
          linkType: 'navLink',
          icon: 'users',
        },
        {
          href: '/tenant/tenants',
          i18n: 'layout:sidebarItems.myTenants',
          linkType: 'navLink',
          icon: 'globe',
        },
      ],
    },
    {
      i18n: 'layout:sidebarItems.logs',
      submenu: [
        {
          href: '/userLogs',
          i18n: 'layout:sidebarItems.userLogs',
          linkType: 'navLink',
          icon: 'menu',
        },
        {
          href: '/logs',
          i18n: 'layout:sidebarItems.adminLogs',
          linkType: 'navLink',
          icon: 'menu',
        },
        {
          href: '/paymentLogs',
          i18n: 'layout:sidebarItems.paymentLogs',
          linkType: 'navLink',
          icon: 'menu',
        },
      ],
    },
    // {
    //   href: '/wifi_settings',
    //   i18n: 'layout:sidebarItems.wifi_settings',
    //   linkType: 'navLink',
    //   icon: 'wifi',
    // },
  ],
}

const ZohmAdmin = {
  ...SolverAdmin,
  appName: 'Zohm Admin',
}

const SmartSensor = {
  key: 'sensor',
  appName: 'Smart Sensor',
  filterObjectTypes: [13],
  footer: [
    {
      href: '/settings',
      i18n: 'layout:sidebarItems.settings',
      linkType: 'navLink',
      icon: '',
    },
  ],
  menu: [
    {
      href: '/objects/access',
      i18n: 'layout:sidebarItems.visitAccessObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/objects/delegate',
      i18n: 'layout:sidebarItems.visitDelegatedObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/userLogs',
      i18n: 'layout:sidebarItems.logs',
      linkType: 'navLink',
      icon: 'menu',
    },
  ],
}

const SolverVisit = {
  key: 'visit',
  appName: 'Solver Visit',
  filterObjectTypes: [2, 16, 18],
  footer: [
    {
      href: '/settings',
      i18n: 'layout:sidebarItems.settings',
      linkType: 'navLink',
      icon: '',
    },
  ],
  menu: [
    {
      href: '/objects/access',
      i18n: 'layout:sidebarItems.visitAccessObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/objects/delegate',
      i18n: 'layout:sidebarItems.visitDelegatedObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/visiting',
      title: 'Visiting',
      // i18n: 'layout:sidebarItems.visitDelegatedObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/visitors',
      title: 'Visitors',
      // i18n: 'layout:sidebarItems.visitDelegatedObjects',
      icon: 'cube',
      linkType: 'navLink',
    },
    {
      href: '/userLogs',
      i18n: 'layout:sidebarItems.logs',
      linkType: 'navLink',
      icon: 'menu',
    },
  ],
}

const getConfig = () => {
  if (window.location.hostname.includes('greenstat')) {
    return Greenstat
  } else if (window.location.hostname.includes('zohm')) {
    return ZohmAdmin
  } else if (window.location.hostname.includes('admin')) {
    return SolverAdmin
  } else if (window.location.hostname.includes('sensor')) {
    return SmartSensor
  } else if (window.location.hostname.includes('visit')) {
    return SolverVisit
  } else {
    return SolverAdmin
  }
}

const defaultConfig = {
  version: process.env.REACT_APP_VERSION,
  build: process.env.REACT_APP_BUILD_NUM,
}

const config = {
  ...defaultConfig,
  ...getConfig(),
}

export default config
