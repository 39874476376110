import React from 'react'
import Messaging from 'containers/SolverAdmin/Forms/Messaging'

const MessagesTab = ({ object }) => {
  //  --- Response ---
  return (
    <div className="mb-5">
      <div className="flex flex-col space-y-5 border-b pb-5 lg:flex-row lg:space-x-5 lg:space-y-0 lg:border-b-0 lg:pb-0">
        <div className="flex w-full flex-col space-y-4">
          <Messaging object={object} />
        </div>
      </div>
    </div>
  )
}

export default MessagesTab
