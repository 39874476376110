import React from 'react'

const TextArea = ({ children, className, placeholder, value, ...props }) => {
  return (
    <textarea
      value={value ? value : ''}
      placeholder={placeholder}
      className={`focus:outline-none rounded-lg border bg-white px-3 font-mono focus:border-blue-200 ${className}`}
      {...props}
    >
      {children}
    </textarea>
  )
}

export default TextArea
