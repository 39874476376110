import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, ShimmerCard } from 'components'
import { ObjectDetailsCard } from 'containers/SolverAdmin'
import { adminTableTypes } from 'containers/SolverAdmin/ObjectTables/TableTypes'
import ApiHelper from 'helpers/ApiHelper'
import { AdminTabs } from './config'
import VerticalTabbedCard from 'components/shared/Cards/VerticalTabbedCard'

const AdminObjectsShowView = (props) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const {
    params: { objectId, accessLevel: originalAccessLevel },
  } = useRouteMatch('/objects/:accessLevel/:objectId')

  let accessLevel

  switch (originalAccessLevel) {
    case 'favourites':
      accessLevel = 'access'
      break

    case 'find':
      accessLevel = 'access'
      break

    default:
      accessLevel = originalAccessLevel
      break
  }

  const {
    data: object,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(['object', objectId, { accessLevel }], () => {
    return ApiHelper.showObject(
      objectId,
      accessLevel === 'access' ? 'user' : 'owner'
    )
  })

  const utils = useMemo(
    () => ({
      refetchObject: refetch,
      isFetchingObject: isFetching,
      isChildObject: object ? !!object.parentId : false,
    }),
    [isFetching, object, refetch]
  )

  const tabs = useMemo(
    () => AdminTabs(accessLevel, utils),
    [accessLevel, utils]
  )

  //  --- Response ---
  if (!object || isLoading || error) {
    return (
      <div>
        <Card>
          <div className="space-y-5">
            <ShimmerCard showCircle={true} columnCount={1} />
            <ShimmerCard showCircle={false} columnCount={1} />
          </div>
        </Card>
        <Card className="my-5">
          <ShimmerCard showCircle={false} columnCount={4} />
        </Card>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <ObjectDetailsCard
        object={object}
        accessLevel={accessLevel}
        refetchRef={refetch}
      />
      <VerticalTabbedCard className="my-5 h-full" cardType="content">
        {tabs.map((tab) => ({
          component: adminTableTypes[tab.component],
          key: tab.key,
          preload: tab.preload,
          heading: tab.title || t(tab.i18n),
          props: {
            id: object.objectId,
            ...(tab.getProps != null ? tab.getProps(object) : {}),
          },
        }))}
      </VerticalTabbedCard>
    </div>
  )
}

export default AdminObjectsShowView
