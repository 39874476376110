import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

const useQueryParam = (paramKey, defaultValue = undefined) => {
  const location = useLocation()
  const history = useHistory()
  const paramValue =
    new URLSearchParams(location.search).get(paramKey) || defaultValue

  const setParam = useCallback(
    (val) => {
      let allParams = new URLSearchParams(location.search)
      allParams.set(paramKey, val)
      history.replace(`?${allParams.toString()}`)
    },
    [paramKey, history, location]
  )

  return [paramValue, setParam]
}

export { useQueryParam }
