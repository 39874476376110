import React from 'react'
import { Icon, ActiveIcon } from 'components'

const SurveyActiveIcon = ({ row }) => {
  return (
    <div className="flex w-full">
      {row.status === 'Draft' ? (
        <Icon
          iconName="clock"
          className="mx-auto pl-2"
          color="text-yellow-300"
        />
      ) : (
        <ActiveIcon className="mx-auto pl-2" active={row.completed} />
      )}
    </div>
  )
}

export default SurveyActiveIcon
