import React from 'react'
import { Button, FlexibleModal } from 'components'

const CommandResultModal = ({ data, onClose }) => {
  //  --- Response ---
  return (
    <FlexibleModal size="modal-md" visible={true}>
      <pre className="max-h-96 overflow-auto">
        {JSON.stringify(data, null, 2)}
      </pre>
      <div className="flex flex-row justify-end space-x-4">
        <Button
          className="bg-green-400 text-white hover:bg-green-500"
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </FlexibleModal>
  )
}

export default CommandResultModal
