export function sortStatusTextByLanguage(map, language) {
  for (let i = 0; i < map.length; i++) {
    if (map[i].language.toLowerCase().includes(language)) {
      return map[i].text
    }
  }

  if (map.length === 0) {
    return []
  }

  return map[0].text
}
