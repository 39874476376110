import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
} from 'react-table'
import { Trans } from 'react-i18next'
import { FixedSizeList } from 'react-window'
import { Icon } from '../'
import './Table.css'

const ReactTable = forwardRef(
  ({ initialSort, removeFooter, rowOnClick, ...props }, ref) => {
    //  --- Variables ---
    const columns = useMemo(() => props.columns, [props.columns])
    const data = useMemo(() => props.data, [props.data])
    const tableContentRef = useRef()
    const listRef = useRef()

    //  --- Setup ---
    const {
      getTableProps,
      getTableBodyProps,
      selectedFlatRows,
      totalColumnsWidth,
      footerGroups,
      headerGroups,
      rows,
      prepareRow,
      state: { selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [initialSort],
        },
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns,
      useExpanded,
      useRowSelect
    )

    useImperativeHandle(ref, () => ({
      getSelectedRows() {
        return selectedFlatRows
      },
    }))

    //  --- Functionality ---
    const RenderRow = useCallback(
      ({ index, style }) => {
        const row = rows[index]
        prepareRow(row)
        return (
          <tr
            className={`${row.isSelected ? 'bg-gray-200' : ''} ${
              rowOnClick != null ? 'cursor-pointer hover:bg-gray-300' : ''
            } ${index % 2 !== 0 ? 'bg-gray-50' : ''} `}
            {...row.getRowProps({ style })}
          >
            {row.cells.map((cell, index) => {
              let overrideOnClick =
                row.original.overrideOnClick &&
                row.original.overrideOnClick.length > 0
                  ? row.original.overrideOnClick.includes(index)
                  : false
              return (
                <td
                  onClick={() => {
                    if (rowOnClick && !overrideOnClick) {
                      rowOnClick(
                        row.original,
                        listRef.current.state.scrollOffset,
                        row
                      )
                    }
                  }}
                  {...cell.getCellProps()}
                  className="overflow-hidden px-1"
                >
                  {cell.render('Cell')}
                </td>
              )
            })}
          </tr>
        )
      },
      [rows, prepareRow, rowOnClick, listRef]
    )

    //  --- Response ---
    return (
      <table className="data-table h-full w-full" {...getTableProps()}>
        <thead className="table-header-group w-full">
          {headerGroups.map((headerGroup) => {
            return (
              <tr
                className="text-left text-gray-600"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      {...header.getHeaderProps()}
                      className="overflow-hidden"
                    >
                      <div {...header.getSortByToggleProps()}>
                        <span className="inline-flex">
                          <Trans i18nKey={header.i18n}>
                            {header.render('Header')}
                          </Trans>

                          <span>
                            {header.isSorted ? (
                              header.isSortedDesc ? (
                                <Icon iconName="sort-desc" className="pl-1" />
                              ) : (
                                <Icon iconName="sort-asc" className="pl-1" />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </span>
                        {/* Use column.getResizerProps to hook up the events correctly */}
                      </div>
                      {header.resizable !== false && header.getResizerProps ? (
                        <div
                          {...header.getResizerProps()}
                          className={`z-1 absolute top-0 right-0 inline-block h-full w-1 bg-gray-300 hover:bg-gray-600 ${
                            header.isResizing ? 'bg-black' : ''
                          }`}
                        />
                      ) : null}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody
          ref={tableContentRef}
          className="data-table-body h-full w-full"
          {...getTableBodyProps()}
        >
          {tableContentRef.current != null ? (
            <FixedSizeList
              ref={listRef}
              initialScrollOffset={props.scrollOffset}
              itemSize={35}
              style={{
                overflowX: 'hidden',
              }}
              tableWidths={totalColumnsWidth}
              selectedRowIds={selectedRowIds}
              itemCount={rows.length}
              height={
                props.height
                  ? props.height
                  : tableContentRef.current.scrollHeight
              }
              width={'100%'}
            >
              {RenderRow}
            </FixedSizeList>
          ) : null}
        </tbody>
        {removeFooter ? null : (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {!props.loading && column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    )
  }
)

export default ReactTable
