import React, { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageSwitch } from '../'
import ApiHelper from 'helpers/ApiHelper'
import Icon from '../Icons/Icon'
import './Header.css'

const Header = () => {
  //  --- Variables ---
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { data, isLoading, error } = useQuery(
    ['showUser'],
    () => ApiHelper.showUser(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const locationSegments = location.pathname.split('/').length

  //  --- Functionality ---
  const usersShowViewRedirect = useCallback(() => {
    history.push('/account')
  }, [history])

  //  --- Response ---
  return (
    <div className="h-30 hidden flex-row justify-between md:flex md:py-5">
      <div>
        {locationSegments > 3 ? (
          <div
            className="my-auto flex cursor-pointer flex-row text-gray-600 hover:text-blue-600"
            onClick={() => history.goBack()}
          >
            <span className="flex flex-col justify-center">
              <Icon iconName="chev-left" color="" />
            </span>
            <span className="py-0.5">{t('application:back')}</span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-row space-x-5">
        {error || isLoading ? (
          ''
        ) : (
          <div className="justify-right flex flex-col">
            <p
              onClick={usersShowViewRedirect}
              className="ml-auto cursor-pointer font-semibold text-blue-500"
            >
              {data.displayName}
            </p>
            <p className="text-sm text-gray-400">{data.username}</p>
          </div>
        )}
        <LanguageSwitch />
      </div>
    </div>
  )
}

export default Header
