import React, { useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'

import { Button, FilteredTable } from 'components'

import { getDate, toLocalTime } from 'helpers/DateTimeHelper'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { BasicDateInput } from 'components/shared/Inputs/DateInput'

const ObjectTelemetryTransformTable = ({ id, ...props }) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  // const [fromDate, setFrom] = useQueryParam('from', getDate('yesterday'))
  // const [toDate, setTo] = useQueryParam('to', getDate())

  const searchParams = new URLSearchParams(search)

  const fromDate = searchParams.get('from') || getDate('yesterday')
  const toDate = searchParams.get('to') || getDate()

  // const [tempFromDate, setTempFromDate] = useState(fromDate)
  // const [tempToDate, setTempToDate] = useState(toDate)
  const [enabled, setEnabled] = useState(false)

  const [tableHeaders, setTableHeaders] = useState()

  //  --- Functionality ---
  const dataFormatter = useCallback((result) => {
    var newHeaders = [...defaultTableHeaders]
    if (result != null && result.transforms != null) {
      result.transforms.forEach((transform) => {
        newHeaders.push({
          Header: transform.telemetryValue,
          accessor: transform.telemetryValue,
          width: 9.0 * transform.telemetryValue.length + 42.0,
        })
      })

      setTableHeaders(newHeaders)

      return result.data
    } else {
      return []
    }
  }, [])

  const headers = useCallback(
    () => tableHeaders || defaultTableHeaders,
    [tableHeaders]
  )

  //  --- Response ---
  return (
    <FilteredTable
      apiName={[`indexObjectTelemetryTransform`, id, fromDate, toDate]}
      dataApi={() =>
        ApiHelper.indexObjectTelemetryTransformed(id, fromDate, toDate)
      }
      dataFormatter={dataFormatter}
      filterDebounce={true}
      initialSort={{ id: 'sampleTime', desc: true }}
      placeholder={t('application:search')}
      removeFooter={true}
      downloadUrls={{
        json: () =>
          `/api/bridge/${id}/telemetry/transform?from=${fromDate}&to=${toDate}`,
      }}
      showRefreshButton={false}
      tableHeaders={headers()}
      queryProps={{
        ...props.queryProps,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled,
      }}
    >
      <form
        className="flex flex-row space-x-2"
        onSubmit={(e) => {
          e.preventDefault()

          setEnabled(true)

          try {
            // const tfd = new Date(tempFromDate).toISOString()
            // const ttd = new Date(tempToDate).toISOString()
            const tfd = new Date(e.target.elements.fromDate.value).toISOString()
            const ttd = new Date(e.target.elements.toDate.value).toISOString()

            searchParams.set('from', tfd)
            searchParams.set('to', ttd)
            history.replace({ search: searchParams.toString() })
          } catch (error) {
            console.log('DATE ERROR')
          }
        }}
      >
        {/* <DateInput
        // onChange={(d) => setFrom(d)}
        onChange={(d) => setTempFromDate(d)}
        initialValue={fromDate}
      /> */}
        <BasicDateInput
          name="fromDate"
          defaultValue={new Date(fromDate)}
          // onChange={(isoString) => {
          //   setTempFromDate(isoString)
          // }}
        />
        <ArrowRightIcon className="h-full w-5 p-1" />
        {/* <DateInput
        // onChange={(d) => setTo(d)}
        onChange={(d) => setTempToDate(d)}
        initialValue={toDate}
      /> */}
        <BasicDateInput
          name="toDate"
          defaultValue={new Date(toDate)}
          // onChange={(isoString) => {
          //   setTempToDate(isoString)
          // }}
        />
        <Button
          type="submit"
          // onClick={() => {
          //   searchParams.set('from', tempFromDate)
          //   searchParams.set('to', tempToDate)
          //   history.replace({ search: searchParams.toString() })
          // }}
        >
          Submit
        </Button>
      </form>
    </FilteredTable>
  )
}

const defaultTableHeaders = [
  // {
  //   Header: 'ID',
  //   accessor: 'telemetryId',
  //   width: 100,
  // },
  {
    Header: 'Sample Time',
    accessor: 'sampleTime',
    width: 180,
    Cell: (props) =>
      props.row.original.sampleTime
        ? toLocalTime(props.row.original.sampleTime)
        : '',
  },
  {
    Header: 'Created Time',
    accessor: 'createdAt',
    width: 180,
    Cell: (props) => toLocalTime(props.row.original.createdAt),
  },
]

export default ObjectTelemetryTransformTable
