import React, { useCallback } from 'react'
import { FilteredTable, ObjectLocation } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toLocalTime } from 'helpers/DateTimeHelper'

const ObjectLogsTable = ({ id, ...props }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isAccess = pathname.includes('access')

  //  --- Functions ---
  const formatTableData = useCallback((logs) => {
    const outputData = []

    for (let i = 0; i < logs.length; i++) {
      const log = logs[i]
      outputData.push({
        id: String(log.objectLogId),
        time: toLocalTime(log.createdAt),
        name: log.objectName,
        location: (
          <ObjectLocation
            latitude={log.latitude}
            longitude={log.longitude}
            className="px-7"
          />
        ),
        user: log.userName,
        details: log.details,
        user_location: (
          <ObjectLocation
            latitude={log.mobLatitude}
            longitude={log.mobLongitude}
            className="px-12"
          />
        ),
        action: log.action,
        result: log.status,
      })
    }

    return outputData
  }, [])

  //  --- Response ---
  return (
    <FilteredTable
      apiName={[`indexObjectLogs`, id]}
      dataFormatter={formatTableData}
      dataApi={() =>
        isAccess
          ? ApiHelper.indexUserObjectLogs(id)
          : ApiHelper.indexObjectLogs(id)
      }
      filterDebounce={true}
      queryProps={{ refetchOnWindowFocus: false }}
      placeholder={t('application:search')}
      initialSort={{ id: 'time', desc: true }}
      downloadUrls={props.downloadUrls}
      removeFooter={true}
      showRefreshButton={true}
      tableHeaders={tableHeaders()}
    />
  )
}

const tableHeaders = () => [
  // {
  //   Header: 'ID',
  //   i18n: 'showObject:objectLogsTable.id',
  //   accessor: 'id',
  //   width: 80,
  // },
  {
    Header: 'Time',
    i18n: 'showObject:objectLogsTable.time',
    accessor: 'time',
    width: 180,
  },
  {
    Header: 'Action',
    i18n: 'showObject:objectLogsTable.action',
    accessor: 'action',
    width: 180,
  },
  {
    Header: 'Name',
    i18n: 'showObject:objectLogsTable.name',
    accessor: 'name',
    width: 280,
  },
  {
    Header: 'Location',
    i18n: 'showObject:objectLogsTable.location',
    accessor: 'location',
    width: 90,
  },
  {
    Header: 'User',
    i18n: 'showObject:objectLogsTable.user',
    accessor: 'user',
    width: 180,
  },
  {
    Header: 'User Location',
    i18n: 'showObject:objectLogsTable.userLocation',
    accessor: 'user_location',
  },
  {
    Header: 'Details',
    i18n: 'showObject:objectLogsTable.details',
    accessor: 'details',
    width: 280,
  },
  {
    Header: 'Result',
    i18n: 'showObject:objectLogsTable.result',
    accessor: 'result',
    width: 100,
  },
]

export default ObjectLogsTable
