import { useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  sortObjectsWithChildren,
  sortObjectsWithChildrenSelect,
  tableHeaders,
} from '../config'

import ApiHelper from 'helpers/ApiHelper'
import { prefetchQuery } from 'helpers/QueryHelper'

import { CreateVisitObjectModal } from 'containers'
import CreateMasterlockModal from 'containers/SolverAdmin/Objects/CreateMasterlockModal'
import CreateDanalockModal from 'containers/SolverAdmin/Objects/CreateDanalockModal'

import { Card, FilteredTable, MenuButton } from 'components'

import UserAccessModal from './UserAccessModal'
import UserAdminModal from './UserAdminModal'
import GroupAccessModal from './GroupAccessModal'
import TenantAccessModal from './TenantAccessModal'

export default function AdminObjectsIndexView() {
  const { t } = useTranslation()
  const history = useHistory()
  const tableRef = useRef()
  const refreshRef = useRef()

  const {
    params: { accessLevel },
  } = useRouteMatch('/objects/:accessLevel')

  const [visibleModal, setVisibleModal] = useState(null)

  const getObjectIds = () =>
    tableRef.current.getSelectedRows().map((r) => r.original.objectId)

  const onDone = () => {
    setVisibleModal(null)

    if (refreshRef.current) refreshRef.current()
  }

  const dataApi = () =>
    accessLevel === 'delegate'
      ? ApiHelper.indexDelegatedObjects()
      : ApiHelper.indexUserObjects()

  const dataFormatter =
    accessLevel === 'delegate'
      ? sortObjectsWithChildrenSelect
      : sortObjectsWithChildren

  const handleRowClick = (row) => {
    prefetchQuery(['object', row.objectId, { accessLevel }], row)
    history.push(`/objects/${accessLevel}/${row.objectId}`)
  }

  return (
    <>
      <Card cardType="primary-content" className="mb-4 h-full">
        <FilteredTable
          apiName={['indexObjects', accessLevel]}
          dataFormatter={dataFormatter}
          dataApi={dataApi}
          tableRef={tableRef}
          refreshRef={refreshRef}
          filterCacheKey={`indexObjectsFilter_${accessLevel}`}
          filterDebounce={true}
          initialSort={{ id: 'name', desc: false }}
          placeholder={t('application:search')}
          removeFooter={true}
          expandable={true}
          scrollRestore={true}
          rowOnClick={handleRowClick}
          tableHeaders={tableHeaders[accessLevel]}
        >
          {accessLevel === 'delegate' && (
            <MenuButton
              title={t('application:bulk')}
              options={[
                {
                  key: 'add_admin',
                  title: 'Add delegation',
                  onClick: () => setVisibleModal('delegation'),
                },
                {
                  key: 'add_user',
                  title: 'Add user access',
                  onClick: () => setVisibleModal('user-access'),
                },
                {
                  key: 'add_group',
                  title: 'Add group access',
                  onClick: () => setVisibleModal('group-access'),
                },
                {
                  key: 'add_tenant',
                  title: 'Add tenant access',
                  onClick: () => setVisibleModal('tenant-access'),
                },
              ]}
            />
          )}
          <MenuButton
            title={t('application:newObject')}
            options={[
              {
                key: 'master',
                title: 'Masterlock',
                onClick: () => setVisibleModal('masterlock'),
              },
              {
                key: 'dana',
                title: 'Danalock',
                onClick: () => setVisibleModal('danalock'),
              },
              {
                key: 'visit',
                title: 'Visit',
                onClick: () => setVisibleModal('visit'),
              },
            ]}
          />
        </FilteredTable>
      </Card>
      <CreateMasterlockModal
        visible={'masterlock' === visibleModal}
        toggle={() => setVisibleModal(null)}
      />
      <CreateDanalockModal
        visible={'danalock' === visibleModal}
        toggle={() => setVisibleModal(null)}
      />
      <CreateVisitObjectModal
        visible={'visit' === visibleModal}
        toggle={() => setVisibleModal(null)}
      />

      {/* Bulk object operation */}
      <UserAccessModal
        onDone={onDone}
        objectIds={getObjectIds}
        visible={'user-access' === visibleModal}
        toggle={(e) => setVisibleModal(null)}
      />
      <UserAdminModal
        onDone={onDone}
        objectIds={getObjectIds}
        visible={'delegation' === visibleModal}
        toggle={(e) => setVisibleModal(null)}
      />
      <GroupAccessModal
        onDone={onDone}
        objectIds={getObjectIds}
        visible={'group-access' === visibleModal}
        toggle={(e) => setVisibleModal(null)}
      />
      <TenantAccessModal
        onDone={onDone}
        objectIds={getObjectIds}
        visible={'tenant-access' === visibleModal}
        toggle={(e) => setVisibleModal(null)}
      />
    </>
  )
}
