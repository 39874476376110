import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, FilteredTable, ObjectLocation } from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { toLocalTime } from 'helpers/DateTimeHelper'

const UserLogsIndexView = () => {
  //  --- Variables ---
  const { t } = useTranslation()

  //  --- Response ---
  return (
    <Card cardType="primary-content" className="mb-4 h-full">
      <FilteredTable
        apiName="userLogs"
        dataApi={() => ApiHelper.indexUserLogs()}
        filterDebounce={true}
        filterCacheKey="userLogs"
        initialSort={{ id: 'createdAt', desc: true }}
        placeholder={t('application:search')}
        showRefreshButton={true}
        removeFooter={true}
        tableHeaders={tableHeaders}
      />
    </Card>
  )
}

const tableHeaders = [
  // {
  //   Header: 'ID',
  //   i18n: 'indexObjects:logsTable.id',
  //   accessor: 'objectLogId',
  //   width: 70,
  // },
  {
    Header: 'Time',
    i18n: 'indexObjects:logsTable.time',
    accessor: 'createdAt',
    width: 200,
    Cell: (props) => {
      return toLocalTime(props.row.original.createdAt)
    },
  },
  {
    Header: 'Name',
    i18n: 'indexObjects:logsTable.name',
    accessor: 'objectName',
    width: 350,
  },
  {
    Header: 'Location',
    i18n: 'indexObjects:logsTable.location',
    accessor: 'latitude',
    width: 100,
    Cell: (props) => (
      <ObjectLocation
        latitude={props.row.original.latitude}
        longitude={props.row.original.longitude}
      />
    ),
  },
  {
    Header: 'User',
    i18n: 'indexObjects:logsTable.user',
    accessor: 'userName',
    width: 200,
  },
  {
    Header: 'User Location',
    i18n: 'indexObjects:logsTable.userLocation',
    accessor: 'mobLatitude',
    width: 125,
    Cell: (props) => (
      <ObjectLocation
        latitude={props.row.original.mobLatitude}
        longitude={props.row.original.mobLongitude}
      />
    ),
  },
  {
    Header: 'Action',
    i18n: 'indexObjects:logsTable.action',
    accessor: 'action',
    width: 175,
  },
  {
    Header: 'Result',
    i18n: 'indexObjects:logsTable.result',
    accessor: 'status',
    width: 70,
  },
]

export default UserLogsIndexView
