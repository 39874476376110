import React, { useState, useEffect } from 'react'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'

import { DateInput, LineGraph } from 'components'

import { getDate } from 'helpers/DateTimeHelper'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useQuery } from '@tanstack/react-query'

import { Spinner, Button, MenuButton } from '../../components/shared'
import useFileDownload from 'helpers/FileDownloadHook'

import { useQueryParam } from 'hooks/useQueryParam'

const ObjectTelemetryTransformGraphs = ({ id, ...props }) => {
  //  --- Variables ---
  const { t } = useTranslation()
  const [fromDate, setFrom] = useQueryParam('from', getDate('yesterday'))
  const [toDate, setTo] = useQueryParam('to', getDate())
  const queryProps = props.queryProps
  const showRefreshButton = true
  const [rows, setRows] = useState([])
  const [graphs, setGraphs] = useState([])

  const { fetchFile } = useFileDownload()

  //  --- Response ---
  const { data, isFetching, isLoading, refetch } = useQuery(
    [`indexObjectTelemetryTransform`, id, fromDate, toDate],
    () => ApiHelper.indexObjectTelemetryTransformed(id, fromDate, toDate),
    queryProps
  )
  //  --- Setup ---
  useEffect(() => {
    if (data === undefined) {
      setRows([])
    } else {
      setRows(data.data.reverse())
      setGraphs(data.transforms)
    }
  }, [data])

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row">
        <div className="flex flex-row justify-end space-x-2 md:flex-grow">
          {isLoading || isFetching ? (
            <div className="flex">
              <Spinner className="my-auto mr-5" />
            </div>
          ) : null}

          <DateInput onChange={(d) => setFrom(d)} initialValue={fromDate} />
          <ArrowRightIcon className="h-full w-5 p-1" />
          <DateInput onChange={(d) => setTo(d)} initialValue={toDate} />

          {showRefreshButton ? (
            <Button onClick={refetch}> {t('application:refresh')} </Button>
          ) : null}
          {data != null && props.downloadUrls ? (
            <MenuButton
              title={t('application:download')}
              options={[
                {
                  key: 'json',
                  title: `${t('application:download')} JSON`,
                  onClick: () =>
                    fetchFile(props.downloadUrls.json, 'export.json'),
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      {graphs.length > 0 ? (
        <div className="mt-4 h-96 space-y-8 overflow-y-auto">
          <div className="flex h-96 w-full flex-row flex-wrap">
            {graphs.map((graph) => (
              <div className="w-1/2" key={graph.transformationId}>
                <LineGraph
                  data={rows}
                  domain="sampleTime"
                  seriesColour="#82ca9d"
                  series={graph.telemetryValue}
                  title={graph.telemetryValue}
                  min={true}
                  max={true}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <p>Nothing to display</p>
        </div>
      )}
    </div>
  )
}

export default ObjectTelemetryTransformGraphs
