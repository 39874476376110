import React from 'react'
import cn from 'classnames'

const TickBox = ({ onChange, showValue, value, className, ...props }) => {
  //  --- Response ---
  return (
    <div className="flex h-9 flex-row space-x-3 px-1">
      <input
        className={cn('my-auto', className)}
        type="checkbox"
        checked={value}
        onChange={onChange}
        {...props}
      />
      {showValue ? <p className="my-auto">{value ? 'True' : 'False'}</p> : null}
    </div>
  )
}

export default TickBox
