import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Button,
  FlexibleModal,
  IndeterminateCheckbox,
  ReactTable,
  Spinner,
  TextInput,
} from 'components'
import ApiHelper from 'helpers/ApiHelper'
import { useTranslation } from 'react-i18next'
import { filterDataByQuery } from 'helpers/ApplicationHelper'

const GroupSyncPicker = ({ id, ...props }) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')
  const [filter, setFilter] = useState('')
  const [rows, setRows] = useState([])

  const tableRef = useRef()
  const filterTimeout = useRef()
  const { data, isFetching } = useQuery(
    ['adGroups'],
    () => ApiHelper.listAdGroups(),
    { refetchOnWindowFocus: false }
  )

  const onSubmit = () => {
    const rows = tableRef.current
      .getSelectedRows()
      .map((row) => row.original.id)
    props.onSubmit(rows)
  }

  useEffect(() => {
    if (data === undefined) {
      setRows([])
    } else {
      setRows(filterDataByQuery(data, filter))
    }
  }, [data, filter])

  const updateFilter = useCallback(
    (event) => {
      const value = event.target.value
      setInputValue(value)
      clearTimeout(filterTimeout.current)
      filterTimeout.current = setTimeout(() => setFilter(value), 500)
    },
    [setFilter, setInputValue, filterTimeout]
  )

  return (
    <FlexibleModal size="modal-md" visible={true}>
      <div className="flex h-full flex-col space-y-2">
        <TextInput
          value={inputValue}
          className="mr-5 flex-grow md:w-1/2"
          onChange={updateFilter}
          placeholder="Filter groups"
        />
        <div className="flex-grow">
          <ReactTable
            ref={tableRef}
            columns={tableHeaders()}
            data={rows}
            height={400}
            initialSort={{ id: 'displayName', desc: true }}
            removeFooter={true}
          />
        </div>
        <div className="flex flex-shrink justify-end">
          {(isFetching || !data) && <Spinner />}
          <Button
            onClick={props.onCancel}
            className="border border-red-200 bg-white text-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white"
          >
            {t('application:cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            className="ml-2 bg-green-400 text-white hover:bg-green-500"
          >
            {t('application:submit')}
          </Button>
        </div>
      </div>
    </FlexibleModal>
  )
}

const tableHeaders = () => [
  {
    id: 'select',
    resizable: false,
    width: 40,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div className="px-1">
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: (props) => (
      <div className="px-1">
        <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
      </div>
    ),
  },
  {
    Header: 'Name',
    i18n: 'indexObjects:objectGroupsTable.name',
    accessor: 'displayName',
    width: 100,
  },
  {
    Header: 'SID',
    i18n: 'indexObjects:objectGroupsTable.sid',
    accessor: 'id',
    width: 200,
  },
]

export default GroupSyncPicker
